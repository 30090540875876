// DiscussionPost.js
import React, { useState , useEffect} from "react";
import { Box, Typography, Button } from "@mui/material";
import ReplyPost from "./ReplyPost";
import NewReplyPost from "./NewReplyPost";
import api from "../../api";

const DiscussionPost = ({ discussion, onAddReply }) => {
  const [showReplies, setShowReplies] = useState(false);
  const [userName, setUserName] = useState("");

  useEffect(() => {
  const fetchUserName = async () => {
    try {
      const response = await api.get(
        `/api/forum/author?id=${discussion.author}`
      );
      if (response.status === 200) {
        setUserName(response.data.author);
      }
    } catch (error) {
      console.error("Error fetching user name:", error);
    }
  };
  fetchUserName();
}, [discussion.author])

const handleToggleReplies = () => {
  setShowReplies(!showReplies);
};

  return (
    <Box sx={{ backgroundColor: "#f0f0f0", borderRadius: "10px", padding: "10px", marginBottom: "10px" }}>
      <Typography variant="body1" color="black">{discussion.content}</Typography>
      <Typography variant="caption" color="textSecondary">
        Posted by {userName} on {new Date(discussion.date).toLocaleDateString()}
      </Typography>
      <Box sx={{ marginTop: "10px" }}>
        <Button onClick={handleToggleReplies} sx={{ display: 'block', align: 'left', marginTop: '10px' }}>
          {showReplies ? "HIDE REPLIES" : "SHOW REPLIES"}
        </Button>
      </Box>
      {showReplies && (
        <Box sx={{ marginTop: "10px" }}>
          {discussion.replies.map((reply, index) => (
            <ReplyPost key={index} reply={reply} />
          ))}
          <NewReplyPost discussionId={discussion._id} onAddReply={onAddReply} />
        </Box>
      )}
    </Box>
  );
};

export default DiscussionPost;
