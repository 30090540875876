import React, { useState, useEffect, useCallback, memo } from 'react';
import { useUser } from '../UserContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Box, Container, Toolbar, Typography, Button } from '@mui/material';
import DropdownMenu from './DropdownMenu';
import UserAuthData from "../UserAuthData";
import SignOutButton from './SignOutButton';
import pages from './NavigationPages';
import HamburgerNavBar from './HamburgerNavBar';
import '../../styles/styles.css';
import '../../styles/tokens.css';

// Constants
const MOBILE_BREAKPOINT = 768;
const buttonStyle = { mx: 2 };

// Extracted components for better organization and reusability
const Logo = memo(() => (
  <Link to='/' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
    <img
      src={process.env.PUBLIC_URL + '/logo76.png'}
      alt='Logo'
      className='logo'
      style={{ marginRight: '10px' }}
    />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: 1.2,
        color: 'inherit',
      }}
    >
      <Typography className='nav-text' sx={{ fontSize: '1rem' }}>
        Synergistic
      </Typography>
      <Typography className='nav-text' sx={{ fontSize: '1rem' }}>
        Intelligence
      </Typography>
    </Box>
  </Link>
));

const NavButton = memo(({ page, isActive, onMenuOpen }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    if (page.link) {
      e.preventDefault();
      navigate(page.link);
    } else {
      onMenuOpen(e);
    }
  };

  return (
    <Button
      onClick={handleClick}
      sx={buttonStyle}
    >
      <Typography
        className='nav-text'
        sx={{
          color: isActive ? 'var(--accent-color)' : 'var(--body-text-color)',
          fontWeight: isActive ? 550 : 400,
          fontSize: '0.875rem',
          margin: '0 10px',
          '&:hover': { color: 'var(--accent-color)' },
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {page.name}
      </Typography>
    </Button>
  );
});

const AuthButtons = memo(({ userData }) => (
  <Box sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
    {userData ? (
      <>
        <Button sx={buttonStyle}>
          <Link to='/dashboard' style={{ textDecoration: 'none' }} className='link'>
            <Typography className='nav-text' sx={{ fontWeight: 300 }}>
              Dashboard
            </Typography>
          </Link>
        </Button>
        <SignOutButton />
      </>
    ) : (
      <Button sx={buttonStyle}>
        <Link to='/login' style={{ textDecoration: 'none' }} className='link'>
          <Typography className='nav-text' sx={{ fontWeight: 300 }}>
            Sign In
          </Typography>
        </Link>
      </Button>
    )}
  </Box>
));

const NavigationBar = () => {
  const { setUserData } = useUser();
  const { userData, isLoading } = UserAuthData();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_BREAKPOINT);

  // Memoized handlers
  const handleOpenMenu = useCallback((event, menuName) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(menuName);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
    setCurrentMenu(null);
  }, []);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
  }, []);

  // Effect for window resize
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  // Effect for user data
  useEffect(() => {
    if (userData) {
      setUserData(userData);
    }
  }, [userData, setUserData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const filteredPages = userData?.role?.includes('admin') || userData?.role?.includes('company')
    ? pages
    : pages.filter((page) => page.name !== 'Mentors');

  return (
    <AppBar position='fixed' elevation={0} style={{ backgroundColor: 'black' }}>
      <Container maxWidth={false}>
        <Toolbar
          disableGutters
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          {isMobile ? (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <HamburgerNavBar />
              </Box>
              <Logo />
              {!userData && <AuthButtons userData={userData} />}
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                <Logo />
              </Box>

              <Box display='flex' alignItems='center' sx={{ flexGrow: 1, justifyContent: 'space-around', overflow: 'hidden' }}>
                {filteredPages.map((page) => (
                  <NavButton
                    key={page.name}
                    page={page}
                    isActive={location.pathname === (page.link || '/editable-page')}
                    onMenuOpen={(event) => handleOpenMenu(event, page.name)}
                  />
                ))}
                {currentMenu && (
                  <DropdownMenu
                    anchorEl={anchorEl}
                    handleCloseMenu={handleCloseMenu}
                    items={filteredPages.find((page) => page.name === currentMenu)?.items}
                  />
                )}
              </Box>

              <AuthButtons userData={userData} />
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default memo(NavigationBar);
