import React from 'react';
import { Box, Button } from '@mui/material';

const CATEGORIES = [
    "View all",
    "Abundance",
    "Education",
    "Innovation",
    "Letting Go",
    "Tools for Awaking",
    "Thriving with AI"
];

const CategoryFilter = ({ selectedCategory, onCategoryChange, isAdmin, onCreateBlog }) => {
    return (
        <Box sx={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            gap: '1rem', 
            marginBottom: '2rem'
        }}>
            {CATEGORIES.map((category, index) => (
                <Button
                    key={index}
                    variant={selectedCategory === category ? "contained" : "outlined"}
                    sx={{ 
                        color: '#FFFFFF', 
                        borderColor: '#FFFFFF',
                        marginBottom: '0.5rem'
                    }}
                    onClick={() => onCategoryChange(category)}
                >
                    {category}
                </Button>
            ))}
            {isAdmin && (
                <Button 
                    variant="contained"
                    onClick={onCreateBlog}
                    sx={{
                        backgroundColor: '#B388FF',
                        color: '#FFFFFF',
                        borderRadius: '8px',
                        padding: '8px 24px',
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        marginBottom: '0.5rem',
                        '&:hover': {
                            backgroundColor: '#9575CD'
                        }
                    }}
                >
                    Create Blog
                </Button>
            )}
        </Box>
    );
};

export default CategoryFilter;
