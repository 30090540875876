export const sections = [
    {
      title: 'Understanding Stagnant Identity',
      questions: [
        { question: 'I often resist new ideas or changes in my routine.', type: 'negative' },
        { question: 'I experience anxiety or discomfort when faced with change.', type: 'negative' },
        { question: 'I am open to new concepts and ideas.', type: 'positive' },
        { question: 'I enjoy trying new things and exploring new routines.', type: 'positive' },
      ],
    },
    {
      title: 'Exploring Survival Instincts',
      questions: [
        { question: 'I feel the need to protect my self-image in social or professional settings.', type: 'negative' },
        { question: 'I frequently compare myself to others.', type: 'negative' },
        { question: 'I am comfortable with my self-image and do not feel the need to defend it.', type: 'positive' },
        { question: 'I focus on my own growth rather than comparing myself to others.', type: 'positive' },
      ],
    },
    {
      title: 'Recognizing Ego Defensiveness',
      questions: [
        { question: 'I feel defensive and justify my actions when receiving constructive criticism.', type: 'negative' },
        { question: 'I find it difficult to admit when I am wrong.', type: 'negative' },
        { question: 'I appreciate constructive feedback and use it to improve.', type: 'positive' },
        { question: 'I am open to admitting my mistakes and learning from them.', type: 'positive' },
      ],
    },
    {
      title: 'Shifting Towards Flow Identity',
      questions: [
        { question: 'I actively seek new experiences and learning opportunities.', type: 'positive' },
        { question: 'I engage in activities outside my comfort zone frequently.', type: 'positive' },
        { question: 'I avoid challenges that push me out of my comfort zone.', type: 'negative' },
        { question: 'I resist change and prefer to stay in my comfort zone.', type: 'negative' },
      ],
    },
    {
      title: 'Cultivating Openness and Awareness',
      questions: [
        { question: 'I am comfortable with uncertainty and not having all the answers.', type: 'positive' },
        { question: 'I practice mindfulness or other forms of self-reflection regularly.', type: 'positive' },
        { question: 'I find it hard to consider viewpoints that differ from my own.', type: 'negative' },
        { question: 'I struggle to understand my biases and limitations.', type: 'negative' },
      ],
    },
    {
      title: 'Deeper Personal Reflection',
      questions: [
        { question: 'My current identity is mostly defined by external achievements and recognition.', type: 'negative' },
        { question: 'There are aspects of myself that I often suppressed or ignored.', type: 'negative' },
        { question: 'I reflect on my past experiences to gain insights into my behavior and choices.', type: 'positive' },
        { question: 'I seek to understand the underlying motivations behind my actions and decisions.', type: 'positive' },
      ],
    },
  ];

  export const archetypeRecommendations = {
    Defender: [
      {
        title: "Skippy",
        description: "Discover how to navigate through fear and uncertainty with greater ease.",
        actionSteps: [
          "Learn about the neuroscience of fear and anxiety",
          "Practice techniques for emotional regulation",
          "Develop a personalized toolkit for managing stress"
        ],
        link: "/blog/67699913592c473ba3dd48c8"
      },
      {
        title: "The Hypnotic Trance",
        description: "Understand the power of your subconscious mind and how it shapes your reality.",
        actionSteps: [
          "Explore the nature of consciousness and awareness",
          "Learn techniques for accessing deeper states of mind",
          "Practice mindfulness and presence"
        ],
        link: "/blog/676603b9592c473ba3dd462c"
      },
      {
        title: "Shadow Work",
        description: "Embrace and integrate the hidden aspects of yourself for greater wholeness.",
        actionSteps: [
          "Identify and acknowledge your shadow aspects",
          "Learn methods for safe shadow exploration",
          "Develop practices for integration and healing"
        ],
        link: "/blog/676604ab592c473ba3dd4653"
      },
      {
        title: "The Paradox of Trying to Survive",
        description: "Transform your relationship with survival instincts and find deeper peace.",
        actionSteps: [
          "Understand the survival paradox",
          "Learn to work with rather than against fear",
          "Develop new perspectives on security and safety"
        ],
        link: "/blog/67699982592c473ba3dd48df"
      }
    ],
    Explorer: [
      {
        title: "Fear and the Turtle",
        description: "Explore the relationship between fear and personal growth through ancient wisdom.",
        actionSteps: [
          "Understand the turtle's teaching about fear",
          "Practice slow, mindful movement",
          "Learn to embrace uncertainty as a path to growth"
        ],
        link: "/blog/67660574592c473ba3dd4674"
      },
      {
        title: "Beyond a Survival Mentality",
        description: "Transcend limiting survival patterns and embrace a more expansive way of being.",
        actionSteps: [
          "Identify survival-based thinking patterns",
          "Develop new mental models for thriving",
          "Practice abundance mindset techniques"
        ],
        link: "/blog/676605db592c473ba3dd4692"
      },
      {
        title: "The Paradox of Control",
        description: "Discover the freedom that comes from letting go of the illusion of control.",
        actionSteps: [
          "Explore the nature of control and surrender",
          "Practice acceptance of uncertainty",
          "Learn to flow with life's challenges"
        ],
        link: "/blog/67660634592c473ba3dd469e"
      },
      {
        title: "Synchronicities",
        description: "Learn to recognize and work with meaningful coincidences in your life.",
        actionSteps: [
          "Understand the nature of synchronicity",
          "Develop awareness of patterns and connections",
          "Practice following intuitive guidance"
        ],
        link: "/blog/676606ce592c473ba3dd470a"
      },
      {
        title: "The Flow State",
        description: "Access and maintain states of optimal performance and creativity.",
        actionSteps: [
          "Learn the conditions for entering flow",
          "Practice flow-inducing activities",
          "Develop a flow-friendly lifestyle"
        ],
        link: "/blog/6766075b592c473ba3dd472b"
      },
      {
        title: "Survival Mind to Flow Mind",
        description: "Transform your consciousness from survival-based to flow-based living.",
        actionSteps: [
          "Understand the differences between survival and flow mind",
          "Practice shifting between mental states",
          "Develop flow-oriented habits"
        ],
        link: "/blog/67699b3c592c473ba3dd48eb"
      }
    ],
    Innovator: [
      {
        title: "Flow Identity",
        description: "Discover and embody your authentic self beyond conventional identities.",
        actionSteps: [
          "Explore the nature of identity and self",
          "Practice presence and authenticity",
          "Develop flow-based self-expression"
        ],
        link: "/blog/676607f9592c473ba3dd474c"
      },
      {
        title: "Scarcity and Abundance",
        description: "Transform your relationship with resources and possibilities.",
        actionSteps: [
          "Understand scarcity and abundance mindsets",
          "Practice abundance visualization",
          "Develop sustainable abundance practices"
        ],
        link: "/blog/676608b8592c473ba3dd480a"
      },
      {
        title: "Portal AI",
        description: "Explore the intersection of consciousness and artificial intelligence.",
        actionSteps: [
          "Learn about AI and consciousness",
          "Explore ethical considerations",
          "Practice conscious technology use"
        ],
        link: "/blog/6766097b592c473ba3dd4840"
      },
      {
        title: "Entrepreneurship",
        description: "Create value through innovative thinking and conscious business practices.",
        actionSteps: [
          "Develop entrepreneurial mindset",
          "Learn conscious business principles",
          "Practice innovative problem-solving"
        ],
        link: "/blog/676609d4592c473ba3dd4861"
      },
      {
        title: "Quantum Vision",
        description: "See beyond conventional reality into quantum possibilities.",
        actionSteps: [
          "Understand quantum principles",
          "Practice quantum visualization",
          "Develop multidimensional awareness"
        ],
        link: "/blog/67660a1c592c473ba3dd486d"
      },
      {
        title: "Free Will: Illusion or Real",
        description: "Explore the nature of choice and consciousness.",
        actionSteps: [
          "Understand free will debates",
          "Practice conscious decision-making",
          "Develop awareness of choice points"
        ],
        link: "/blog/67660a53592c473ba3dd4879"
      },
      {
        title: "In the Flow Identity the Future Exists Now",
        description: "Access future possibilities through present moment awareness.",
        actionSteps: [
          "Understand non-linear time",
          "Practice future visualization",
          "Develop present moment awareness"
        ],
        link: "/blog/67660a95592c473ba3dd4885"
      },
      {
        title: "The Synergist",
        description: "Learn to create synergistic relationships and outcomes.",
        actionSteps: [
          "Understand synergistic principles",
          "Practice collaborative creation",
          "Develop win-win solutions"
        ],
        link: "/blog/67660aca592c473ba3dd489f"
      }
    ]
  };

  export const scoreMapping = {
    positive: { 1: -2, 2: -1, 3: 0, 4: 1, 5: 2 },
    negative: { 1: 2, 2: 1, 3: 0, 4: -1, 5: -2 }
  };
