import { Box, Typography, Container, Button, Paper } from '@mui/material';
import { useState } from 'react';
import FeedbackForm from '../FeedbackForm';

export default function Explorer({name, handleRetakeAssessment}){
    const [showFeedbackForm, setShowFeedbackForm] = useState(false); 

    return (
        <Box sx={{ background: 'var(--background-color)', py: 4 }}>
            <Container maxWidth="md">
                <Paper elevation={3} sx={{ 
                    p: 4, 
                    borderRadius: 2, 
                    mb: 3,
                    backgroundColor: '#0A0A0A',
                    color: 'white',
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '1px',
                        background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
                    }
                }}>
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}>
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h3" component="h1" sx={{ 
                                color: '#B388FF',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 2
                            }}>
                                The Explorer <span role="img" aria-label="explorer">⭐️</span>
                            </Typography>
                            
                            <Typography variant="h4" component="h2" sx={{ mb: 2, color: 'white' }}>
                                Welcome, {name}!
                            </Typography>

                            <Typography variant="body1" sx={{ mb: 3, fontSize: '1.2rem', lineHeight: 1.6, color: '#9E9E9E' }}>
                                Congratulations on completing the Survival Instinct Awareness Assessment! Your results reveal that you are an <Box component="span" sx={{ fontWeight: 'bold', color: '#B388FF' }}>Explorer</Box> - someone who thrives on discovery and embraces new challenges with enthusiasm.
                            </Typography>
                        </Box>

                        <Box sx={{ 
                            display: 'grid', 
                            gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, 
                            gap: 4, 
                            mb: 4 
                        }}>
                            <Box sx={{ 
                                p: 3, 
                                borderRadius: 2, 
                                backgroundColor: '#0A0A0A',
                                border: '1px solid #B388FF',
                                color: 'white',
                                position: 'relative',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    height: '1px',
                                    background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
                                }
                            }}>
                                <Typography variant="h6" sx={{ mb: 2, color: '#B388FF' }}>
                                    Your Core Strengths
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    {['Adaptability', 'Curiosity', 'Open-mindedness', 'Initiative'].map((strength, index) => (
                                        <Typography key={index} sx={{ 
                                            color: '#9E9E9E',
                                            display: 'flex',
                                            alignItems: 'center',
                                            transition: 'color 0.3s ease',
                                            '&:hover': {
                                                color: '#B388FF',
                                            },
                                            '&:before': {
                                                content: '"•"',
                                                color: '#B388FF',
                                                mr: 1,
                                                fontWeight: 'bold'
                                            }
                                        }}>
                                            {strength}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>

                            <Box sx={{ 
                                p: 3, 
                                borderRadius: 2, 
                                backgroundColor: '#0A0A0A',
                                border: '1px solid #B388FF',
                                color: 'white',
                                position: 'relative',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    height: '1px',
                                    background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
                                }
                            }}>
                                <Typography variant="h6" sx={{ mb: 2, color: '#B388FF' }}>
                                    Growth Opportunities
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    {['Focus', 'Commitment', 'Following through', 'Setting boundaries'].map((area, index) => (
                                        <Typography key={index} sx={{ 
                                            color: '#9E9E9E',
                                            display: 'flex',
                                            alignItems: 'center',
                                            transition: 'color 0.3s ease',
                                            '&:hover': {
                                                color: '#B388FF',
                                            },
                                            '&:before': {
                                                content: '"•"',
                                                color: '#B388FF',
                                                mr: 1,
                                                fontWeight: 'bold'
                                            }
                                        }}>
                                            {area}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{ 
                            display: 'flex', 
                            gap: 2,
                            justifyContent: 'center',
                            mt: 2
                        }}>
                            <Button
                                variant="contained"
                                onClick={handleRetakeAssessment}
                                sx={{
                                    backgroundColor: '#B388FF',
                                    color: 'white',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#9B6BFF',
                                        transform: 'translateY(-2px)',
                                    },
                                    px: 4
                                }}
                            >
                                Retake Assessment
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => setShowFeedbackForm(true)}
                                sx={{
                                    color: '#B388FF',
                                    borderColor: '#B388FF',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        borderColor: '#9B6BFF',
                                        backgroundColor: 'rgba(179, 136, 255, 0.1)',
                                        transform: 'translateY(-2px)',
                                    },
                                    px: 4
                                }}
                            >
                                Share Feedback
                            </Button>
                        </Box>
                    </Box>
                </Paper>

                <Container maxWidth="md" sx={{ 
                    backgroundColor: '#0A0A0A', 
                    color: 'white', 
                    p: 4, 
                    borderRadius: 2, 
                    boxShadow: 3, 
                    marginBottom: '1rem',
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '1px',
                        background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
                    }
                }}>
                    <Typography variant="h4" sx={{ fontWeight:'bold', mb: 2, color: '#B388FF' }}>
                        Archetype: The Explorer
                    </Typography>

                    <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                        <img
                            src={process.env.PUBLIC_URL + '/images/explorer.png'}
                            alt="explorer"
                            style={{
                                height: "80%",
                                width: "45%",
                                filter: 'brightness(1.2)',
                            }}
                        />

                        <Box sx={{ ml: 3, mt: 5 }}>
                            <Typography variant="body1" sx={{fontSize: '1.1rem', color: '#9E9E9E'}}>
                                <strong style={{ color: '#B388FF' }}>Key Traits:</strong> Curious, Adaptable, Open-minded, Initiative-taking, Growth-oriented
                            </Typography>

                            <Typography variant="body1" sx={{fontSize: '1.1rem', mt: 3, color: '#9E9E9E' }}>
                                <strong style={{ color: '#B388FF' }}>Your Journey:</strong> You've developed a balanced approach to life, combining curiosity with 
                                calculated risk-taking. Your moderate survival instinct allows you to venture beyond comfort 
                                zones while maintaining awareness. You excel at learning from experience and adapting to 
                                new situations. Continue embracing change while staying grounded in your values.
                            </Typography>
                        </Box>
                    </Box>

                    <Typography variant="body1" sx={{ mb: 2, mt: 4, fontSize: '1.1rem', color: '#9E9E9E'}}>
                        <strong style={{ color: '#B388FF' }}>Why This Matters: </strong> <br/>
                        Your curiosity and openness have already allowed you to grow in many areas, but some
                        survival instincts may still be holding you back in other aspects of life. By recognizing
                        where you're still resistant, you can gently challenge these instincts, uncover new
                        opportunities, and expand your horizons. Growth is not about leaving behind what
                        makes you feel secure—it's about integrating new experiences that can enhance your
                        journey. Imagine the possibilities when you balance your sense of safety with a
                        willingness to step into new, inspiring territory!

                        <br/><br/>
                        <strong style={{ color: '#B388FF' }}>Keep Exploring: </strong> <br/>
                        Continue to push the boundaries of your comfort zone. Dive into resources that
                        challenge your current perspectives and connect with others who can offer fresh
                        insights. The more you explore, the more you'll find that even in areas where you've
                        been firm, there's room for transformation and growth. Our community is here to support
                        your journey, offering you the tools and inspiration to become even more fluid in your
                        thinking and actions.

                        <br/><br/>
                        <strong style={{ color: '#B388FF' }}>Intrigued?</strong> <br/>
                        Join a conversation on the SI platform to exchange ideas with fellow Explorers and
                        mentors. Share your unique blend of curiosity and caution and discover new ways to
                        explore the unfamiliar. Your journey toward deeper awareness and an even more
                        expansive life awaits—start the adventure today!
                    </Typography>
                </Container>
            </Container>

            <FeedbackForm open={showFeedbackForm} onClose={() => setShowFeedbackForm(false)} />
        </Box>
    );
}