import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const OutlinedButton = ({ children, onClick, ...props }) => (
  <Button
    variant="outlined"
    onClick={onClick}
    sx={{
      padding: '0.7rem 1.5rem',
      backgroundColor: 'transparent',
      border: '2px solid white',
      borderRadius: '5px',
      color: 'white',
      fontSize: '1rem',
      fontWeight: 'bold',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: 'white',
        color: 'black',
        borderColor: 'white',
      },
      ...props.sx
    }}
    {...props}
  >
    {children}
  </Button>
);

OutlinedButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default OutlinedButton;
