import React from 'react';
import { Container, Typography, Card, CardContent, CardActions, Button, Box, List, ListItem, ListItemIcon, ListItemText, Fade, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { archetypeRecommendations } from "./AssessmentQuestions";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

const Recommendations = ({ sectionScores = [], archetype }) => {
  const archetypeRecs = archetypeRecommendations[archetype] || [];

  return (
    <Box sx={{ background: 'var(--background-color)', py: 4 }}>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ 
          p: 4, 
          borderRadius: 2, 
          mb: 3,
          backgroundColor: '#0A0A0A',
          color: 'white',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '1px',
            background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
          }
        }}>
          <Box sx={{ 
            color: "white",
            position: 'relative',
            overflow: 'hidden'
          }}>
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'radial-gradient(circle at top right, rgba(var(--accent-color-rgb), 0.1) 0%, rgba(0,0,0,0) 70%)',
              pointerEvents: 'none'
            }} />
            
            <Box sx={{ position: 'relative', zIndex: 1 }}>
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 'bold',
                  fontSize: { xs: '2rem', md: '2.5rem' },
                  mb: 2,
                  background: 'linear-gradient(45deg, var(--accent-color) 30%, #fff 90%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Your Growth Journey as {archetype}
              </Typography>
              
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 4,
                  color: 'rgba(255,255,255,0.8)',
                  maxWidth: '800px'
                }}
              >
                Based on your assessment, we've curated specific resources to support your transformative journey.
              </Typography>

              {/* Archetype-specific recommendations */}
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                gap: 3
              }}>
                {archetypeRecs.map((rec, index) => (
                  <Fade in={true} timeout={500 + index * 200} key={index}>
                    <Card sx={{ 
                      backgroundColor: 'rgba(255,255,255,0.05)',
                      backdropFilter: 'blur(10px)',
                      border: '1px solid rgba(255,255,255,0.1)',
                      transition: 'transform 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        backgroundColor: 'rgba(255,255,255,0.08)',
                      }
                    }}>
                      <CardContent sx={{ p: 4 }}>
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            fontWeight: 'bold',
                            color: 'var(--accent-color)',
                            mb: 2,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                          }}
                        >
                          <LightbulbOutlinedIcon /> {rec.title}
                        </Typography>
                        
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            color: 'rgba(255,255,255,0.9)',
                            mb: 3,
                            fontSize: '1.1rem',
                            lineHeight: 1.6
                          }}
                        >
                          {rec.description}
                        </Typography>

                        <List sx={{ mb: 2 }}>
                          {rec.actionSteps.map((step, stepIndex) => (
                            <ListItem key={stepIndex} sx={{ px: 0 }}>
                              <ListItemIcon sx={{ minWidth: 36 }}>
                                <ArrowForwardIcon sx={{ color: 'var(--accent-color)' }} />
                              </ListItemIcon>
                              <ListItemText 
                                primary={step} 
                                sx={{ 
                                  '& .MuiListItemText-primary': { 
                                    color: 'rgba(255,255,255,0.8)',
                                    fontSize: '0.95rem'
                                  }
                                }} 
                              />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>

                      <CardActions sx={{ px: 4, pb: 3 }}>
                        <Button 
                          variant="outlined"
                          component={Link}
                          to={rec.link}
                          endIcon={<ArrowForwardIcon />}
                          sx={{
                            borderColor: 'var(--accent-color)',
                            color: 'var(--accent-color)',
                            '&:hover': {
                              borderColor: 'var(--accent-color)',
                              backgroundColor: 'rgba(var(--accent-color-rgb), 0.1)',
                            }
                          }}
                        >
                          Explore Resources
                        </Button>
                      </CardActions>
                    </Card>
                  </Fade>
                ))}
              </Box>

              {!archetypeRecs.length && (
                <Box sx={{ mt: 4 }}>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      textAlign: 'center',
                      color: 'rgba(255,255,255,0.8)'
                    }}
                  >
                    Great job! Continue exploring our resources to deepen your practice.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Recommendations;
