// Payment.js

import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import UserAuthData from "../components/UserAuthData";
import DashNavBar from "../components/DashNavBar";
import "../styles/styles.css";
import "../styles/tokens.css";

const drawerWidth = 300;

export default function Payment() {
  const { userAuth, isLoading } = UserAuthData();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Adjust main box conponent based on window size
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!userAuth) {
    return <div>Please Log In to View</div>
  }
  return (
    <Box sx={{ position: "relative" }}>
      <DashNavBar userAuth={userAuth} page={"Payment"} />
      <Box
        component="main"
        sx={{
          position: "absolute",
          top: "65px",
          left: windowWidth < 600 ? 0 : drawerWidth,
          width: `calc(100% - ${windowWidth < 600 ? 0 : drawerWidth}px)`,
          minHeight: "100vh",
          p: 3,
        }}
      >
        <Typography className="header">Payment</Typography>
      </Box>
    </Box>
  );
}
