// SignUp.js

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Alert,
  alpha,
  Container,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import StarsIcon from '@mui/icons-material/Stars';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { motion } from "framer-motion";
import api from "../api";

const FloatingElement = ({ children, delay = 0, duration = 2 }) => {
  return (
    <motion.div
      style={{ display: 'inline-block' }}
      animate={{
        y: [0, -10, 0],
      }}
      transition={{
        duration: duration,
        ease: "easeInOut",
        times: [0, 0.5, 1],
        repeat: Infinity,
        delay: delay,
      }}
    >
      {children}
    </motion.div>
  );
};

const LinkedInIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="24" 
    height="24" 
    viewBox="0 0 24 24"
    style={{ marginRight: '8px' }}
  >
    <path 
      fill="currentColor" 
      d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.68 1.68 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"
    />
  </svg>
);

const QuoteSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, delay: 0.5 }}
      style={{ position: 'relative', zIndex: 2 }}
    >
      <Box sx={{ 
        color: '#fff', 
        mb: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '75vh',
        px: 4
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <FloatingElement delay={0.2} duration={4}>
            <AutoAwesomeIcon sx={{ color: '#ba68c8', fontSize: '3rem' }} />
          </FloatingElement>
        </Box>

        <Box sx={{ maxWidth: '800px', width: '100%' }}>
          <FloatingElement delay={0} duration={4}>
            <Typography 
              variant={isMobile ? "h3" : "h2"} 
              sx={{ 
                mb: 2,
                fontSize: isMobile ? '2.2rem' : '3rem',
                fontWeight: 500,
                textAlign: 'center',
                textShadow: '0 0 20px rgba(186, 104, 200, 0.3)',
              }}
            >
              At Synergistic Intelligence,
            </Typography>
          </FloatingElement>

          <FloatingElement delay={0.3} duration={4}>
            <Typography 
              variant={isMobile ? "h3" : "h2"}
              sx={{ 
                mb: 2,
                fontSize: isMobile ? '2.2rem' : '3rem',
                fontWeight: 500,
                textAlign: 'center',
                textShadow: '0 0 20px rgba(186, 104, 200, 0.3)',
              }}
            >
              we specialize in fostering an environment
            </Typography>
          </FloatingElement>

          <FloatingElement delay={0.6} duration={4}>
            <Typography 
              variant={isMobile ? "h3" : "h2"}
              sx={{ 
                fontStyle: 'italic',
                fontSize: isMobile ? '2.2rem' : '3rem',
                fontWeight: 500,
                textAlign: 'center',
                color: '#ba68c8',
                textShadow: '0 0 20px rgba(186, 104, 200, 0.5)',
                position: 'relative',
                '&::before': {
                  content: 'open-quote',
                  marginRight: '0.2em',
                },
                '&::after': {
                  content: 'close-quote',
                  marginLeft: '0.2em',
                }
              }}
            >
              where the whole is greater than sum of its parts
            </Typography>
          </FloatingElement>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <FloatingElement delay={0.9} duration={4}>
            <StarsIcon sx={{ color: '#ba68c8', fontSize: '3rem' }} />
          </FloatingElement>
        </Box>
      </Box>
    </motion.div>
  );
};

const OrbitingIcon = ({ Icon, radius = 40, duration = 10, delay = 0, clockwise = true }) => {
  return (
    <motion.div
      style={{
        position: 'absolute',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
      }}
      animate={{
        rotate: clockwise ? 360 : -360,
      }}
      transition={{
        duration,
        repeat: Infinity,
        ease: "linear",
        delay,
      }}
    >
      <motion.div
        style={{
          position: 'absolute',
          top: -radius,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.7, 1, 0.7],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      >
        <Icon sx={{ 
          color: '#ba68c8',
          fontSize: '2rem',
          filter: 'drop-shadow(0 0 10px rgba(186, 104, 200, 0.5))',
        }} />
      </motion.div>
    </motion.div>
  );
};

const InteractiveScene = () => {
  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%', zIndex: 2 }}>
      <motion.div
        style={{
          position: 'absolute',
          bottom: '10%',
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <EmojiPeopleIcon 
          sx={{ 
            fontSize: '15rem',
            color: '#ba68c8',
            filter: 'drop-shadow(0 0 20px rgba(186, 104, 200, 0.3))'
          }} 
        />
        <OrbitingIcon Icon={RocketLaunchIcon} radius={60} duration={12} />
        <OrbitingIcon Icon={LightbulbIcon} radius={75} duration={15} delay={1} clockwise={false} />
        <OrbitingIcon Icon={SentimentVerySatisfiedIcon} radius={90} duration={18} delay={2} />
      </motion.div>
    </Box>
  );
};

const invalidPasswordAlertMessage =
  "Invalid password. Password must contain:\n" +
  "  - at least 1 number\n" +
  "  - at least 1 special character\n" +
  "  - at least 1 uppercase letter\n" +
  "  - at least 1 lowercase letter";

export default function SignUp() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (event) => {
    setUserData(prev => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  async function addUserToUserPool(email, password) {
    try {
      const response = await api.post("/api/auth/register", {
        email: email,
        password: password,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async function addUserToDB(email, role, userSub) {
    try {
      const response = await api.post("/api/users", {
        name: email,
        role: role.toLowerCase(),
        sub_id: userSub,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async function handleCreateAccount(e) {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    
    try {
      // Confirm matching passwords
      if (userData.password !== userData.confirmPassword) {
        setError("Passwords do not match");
        setIsLoading(false);
        return;
      }

      // Add user to cognito user pool
      const cognitoResp = await addUserToUserPool(userData.email, userData.password);
      if (cognitoResp && cognitoResp.status === 201) {
        // Add user to mongoDB
        const mongoResp = await addUserToDB(
          userData.email,
          userData.role,
          cognitoResp.data.UserSub
        );
        if (mongoResp && mongoResp.status !== 500) {
          navigate(`/account-verification/${userData.email}`);
        }
        // Reset userData
        setUserData({
          email: "",
          password: "",
          confirmPassword: "",
          role: "",
        });
      } else if (cognitoResp && cognitoResp.status === 400) {
        if (cognitoResp.data.code === "InvalidPasswordException") {
          setError(invalidPasswordAlertMessage);
        } else {
          setError(cognitoResp.data.message || "Failed to create account");
        }
      } else {
        setError("Unexpected error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Signup error:", error);
      setError(error.response?.data?.message || "Failed to create account. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  const styles = {
    pageContainer: {
      display: 'flex',
      minHeight: '100vh',
      bgcolor: '#2c1338',
      mt: { xs: '64px', sm: '64px' },
    },
    leftSection: {
      flex: 1,
      position: 'relative',
      display: { xs: 'none', md: 'flex' },
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(45deg, rgba(44,19,56,0.95) 0%, rgba(44,19,56,0.7) 100%)',
        zIndex: 1,
      },
    },
    rightSection: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      bgcolor: '#fff',
      p: 4,
    },
    textField: {
      '& .MuiInputBase-root': {
        color: '#000',
        '&:before': {
          borderBottomColor: alpha('#ba68c8', 0.42),
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottomColor: '#ba68c8',
        },
        '&.Mui-focused:after': {
          borderBottomColor: '#ba68c8',
        },
      },
      '& .MuiInputLabel-root': {
        color: alpha('#000', 0.6),
        '&.Mui-focused': {
          color: '#ba68c8',
        },
      },
    },
    select: {
      '& .MuiSelect-select': {
        color: '#000',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: alpha('#ba68c8', 0.42),
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#ba68c8',
      },
      '& .MuiInput-underline.Mui-focused:after': {
        borderBottomColor: '#ba68c8',
      },
    },
    submitButton: {
      height: 48,
      borderRadius: '8px',
      background: 'linear-gradient(45deg, #9c27b0, #ba68c8)',
      '&:hover': {
        background: 'linear-gradient(45deg, #ba68c8, #9c27b0)',
        transform: 'translateY(-2px)',
        boxShadow: '0 6px 20px rgba(186, 104, 200, 0.25)',
      },
      transition: 'all 0.3s ease-in-out',
    },
    linkedInButton: {
      width: '100%',
      backgroundColor: '#0077B5',
      color: 'white',
      textTransform: 'none',
      fontSize: '16px',
      fontFamily: 'system-ui, -apple-system, sans-serif',
      padding: '10px',
      marginBottom: '16px',
      '&:hover': {
        backgroundColor: '#006097',
      },
    },
  };

  return (
    <Box sx={styles.pageContainer}>
      <Box sx={styles.leftSection}>
        <Box sx={{ position: 'relative', height: '100%', zIndex: 2 }}>
          <QuoteSection />
          <InteractiveScene />
        </Box>
      </Box>

      <Box sx={styles.rightSection}>
        <Container maxWidth="sm">
          <Box sx={{ width: '100%', maxWidth: 400, mx: 'auto' }}>
            <Typography variant={isMobile ? "h4" : "h3"} sx={{ mb: 4, fontWeight: 700, color: '#2c1338', textAlign: 'center' }}>
              Create Account
            </Typography>

            <form onSubmit={handleCreateAccount}>
              <Stack spacing={3}>
                {error && (
                  <Alert severity="error" sx={{ bgcolor: alpha(theme.palette.error.main, 0.1), borderRadius: 1, whiteSpace: 'pre-line' }}>
                    {error}
                  </Alert>
                )}

                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  required
                  value={userData.email}
                  onChange={handleChange}
                  sx={styles.textField}
                />

                <FormControl fullWidth variant="standard" sx={styles.textField}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required
                    value={userData.password}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(prev => !prev)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                          sx={{ color: '#ba68c8' }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <FormControl fullWidth variant="standard" sx={styles.textField}>
                  <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                  <Input
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    required
                    value={userData.confirmPassword}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowConfirmPassword(prev => !prev)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                          sx={{ color: '#ba68c8' }}
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <FormControl fullWidth variant="standard" sx={styles.select}>
                  <InputLabel>Role</InputLabel>
                  <Select
                    name="role"
                    value={userData.role}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value={"Individual"}>Individual</MenuItem>
                    <MenuItem value={"Company"}>Company</MenuItem>
                  </Select>
                </FormControl>

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={isLoading}
                  sx={styles.submitButton}
                >
                  {isLoading ? (
                    <CircularProgress size={24} sx={{ color: '#fff' }} />
                  ) : (
                    'Create Account'
                  )}
                </Button>

                <Box sx={{ textAlign: 'center', position: 'relative', my: 2 }}>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      bgcolor: '#fff',
                      px: 2,
                      color: alpha('#2c1338', 0.6),
                      position: 'relative',
                      zIndex: 1,
                    }}
                  >
                    Or
                  </Typography>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      right: 0,
                      height: '1px',
                      bgcolor: alpha('#2c1338', 0.1),
                      zIndex: 0,
                    }}
                  />
                </Box>

                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<LinkedInIcon />}
                  sx={styles.linkedInButton}
                  onClick={() => {
                    setError("LinkedIn Sign-up is currently under development. Please use email registration.");
                  }}
                >
                  Sign up with LinkedIn
                </Button>

                <Typography variant="body2" align="center" sx={{ color: alpha('#2c1338', 0.8) }}>
                  Already have an account?{" "}
                  <Link 
                    to="/login" 
                    style={{ 
                      color: '#ba68c8',
                      textDecoration: 'none',
                      '&:hover': {
                        color: '#9c27b0',
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    Sign in
                  </Link>
                </Typography>
              </Stack>
            </form>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
