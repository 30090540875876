import { Box, Typography, Container, Button, Paper } from '@mui/material';
import { useState } from 'react';
import FeedbackForm from '../FeedbackForm';
import Recommendations from '../Recommendations';

export default function Defender({name, handleRetakeAssessment, sectionScores}){
    const [showFeedbackForm, setShowFeedbackForm] = useState(false); 

    return (
        <Box sx={{ background: 'var(--background-color)', py: 4 }}>
            <Container maxWidth="md">
                <Paper elevation={3} sx={{ 
                    p: 4, 
                    borderRadius: 2, 
                    mb: 3,
                    backgroundColor: '#0A0A0A',
                    color: 'white',
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '1px',
                        background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
                    }
                }}>
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}>
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h3" component="h1" sx={{ 
                                color: '#B388FF',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 2
                            }}>
                                The Defender <span role="img" aria-label="defender">⭐️</span>
                            </Typography>
                            
                            <Typography variant="h4" component="h2" sx={{ mb: 2, color: 'white' }}>
                                Welcome, {name}!
                            </Typography>

                            <Typography variant="body1" sx={{ mb: 3, fontSize: '1.2rem', lineHeight: 1.6, color: '#9E9E9E' }}>
                                Congratulations on completing the Survival Instinct Awareness Assessment! Your results reveal that you are a <Box component="span" sx={{ fontWeight: 'bold', color: '#B388FF' }}>Defender</Box> - someone who values stability and thoughtful decision-making.
                            </Typography>
                        </Box>

                        <Box sx={{ 
                            display: 'grid', 
                            gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, 
                            gap: 4, 
                            mb: 4 
                        }}>
                            <Box sx={{ 
                                p: 3, 
                                borderRadius: 2, 
                                backgroundColor: '#0A0A0A',
                                border: '1px solid #B388FF',
                                color: 'white',
                                position: 'relative',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    height: '1px',
                                    background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
                                }
                            }}>
                                <Typography variant="h6" sx={{ mb: 2, color: '#B388FF' }}>
                                    Your Core Strengths
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    {['Thorough', 'Definitive', 'Consistent', 'Unwavering', 'Analytical'].map((strength, index) => (
                                        <Typography key={index} sx={{ 
                                            color: '#9E9E9E',
                                            display: 'flex',
                                            alignItems: 'center',
                                            transition: 'color 0.3s ease',
                                            '&:hover': {
                                                color: '#B388FF',
                                            },
                                            '&:before': {
                                                content: '"•"',
                                                color: '#B388FF',
                                                mr: 1,
                                                fontWeight: 'bold'
                                            }
                                        }}>
                                            {strength}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>

                            <Box sx={{ 
                                p: 3, 
                                borderRadius: 2, 
                                backgroundColor: '#0A0A0A',
                                border: '1px solid #B388FF',
                                color: 'white',
                                position: 'relative',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    height: '1px',
                                    background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
                                }
                            }}>
                                <Typography variant="h6" sx={{ mb: 2, color: '#B388FF' }}>
                                    Growth Opportunities
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    {['Flexibility', 'Risk-taking', 'Embracing change', 'Spontaneity'].map((area, index) => (
                                        <Typography key={index} sx={{ 
                                            color: '#9E9E9E',
                                            display: 'flex',
                                            alignItems: 'center',
                                            transition: 'color 0.3s ease',
                                            '&:hover': {
                                                color: '#B388FF',
                                            },
                                            '&:before': {
                                                content: '"•"',
                                                color: '#B388FF',
                                                mr: 1,
                                                fontWeight: 'bold'
                                            }
                                        }}>
                                            {area}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{ 
                            display: 'flex', 
                            gap: 2,
                            justifyContent: 'center',
                            mt: 2
                        }}>
                            <Button
                                variant="contained"
                                onClick={handleRetakeAssessment}
                                sx={{
                                    backgroundColor: '#B388FF',
                                    color: 'white',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#9B6BFF',
                                        transform: 'translateY(-2px)',
                                    },
                                    px: 4
                                }}
                            >
                                Retake Assessment
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => setShowFeedbackForm(true)}
                                sx={{
                                    color: '#B388FF',
                                    borderColor: '#B388FF',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        borderColor: '#9B6BFF',
                                        backgroundColor: 'rgba(179, 136, 255, 0.1)',
                                        transform: 'translateY(-2px)',
                                    },
                                    px: 4
                                }}
                            >
                                Share Feedback
                            </Button>
                        </Box>
                    </Box>
                </Paper>

                <Container maxWidth="md" sx={{ 
                    backgroundColor: '#0A0A0A', 
                    color: 'white', 
                    p: 4, 
                    borderRadius: 2, 
                    boxShadow: 3, 
                    marginBottom: '1rem',
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '1px',
                        background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
                    }
                }}>
                    <Typography variant="h4" sx={{ fontWeight:'bold', mb: 2, color: '#B388FF' }}>
                        Archetype: The Defender
                    </Typography>

                    <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                        <img
                            src={process.env.PUBLIC_URL + '/images/Defender.png'}
                            alt="Defender"
                            style={{
                                height: "80%",
                                width: "45%",
                                filter: 'brightness(1.2)',
                            }}
                        />

                        <Box sx={{ ml: 3, mt: 5 }}>
                            <Typography variant="body1" sx={{fontSize: '1.1rem', color: '#9E9E9E'}}>
                                <strong style={{ color: '#B388FF' }}>Key Traits:</strong> Thorough, Definitive, Risk-averse, Consistent, Unwavering
                            </Typography>

                            <Typography variant="body1" sx={{fontSize: '1.1rem', mt: 3, color: '#9E9E9E' }}>
                                <strong style={{ color: '#B388FF' }}>Your Journey:</strong> You value predictability and consistency. This often means you
                                shy away from new experiences, missing the opportunity to discover inspiring
                                and exciting aspects of life. The more you become aware of these instincts, the
                                more you can step into "the flow" and embrace growth.
                            </Typography>
                        </Box>
                    </Box>

                    <Typography variant="body1" sx={{ mb: 2, mt: 4, fontSize: '1.1rem', color: '#9E9E9E'}}>
                        <strong style={{ color: '#B388FF' }}>Why This Matters: </strong>Your survival instincts, while protective, can sometimes prevent you
                        from reaching your full potential. By gradually shifting these instincts, you open the door
                        to new opportunities, creativity, and personal growth. Imagine what becomes possible
                        when fear no longer holds you back!

                        <br/><br/>
                        <strong style={{ color: '#B388FF' }}>Keep Exploring: </strong>Dive deeper into your results and explore our resources. The more
                        you understand your instincts, the more you can transform them into powerful tools for
                        innovation and growth.

                        <br/><br/>
                        <strong style={{ color: '#B388FF' }}>Intrigued?</strong> Join a conversation on the SI platform or connect with one of our mentors to
                        explore your results further. Your journey to a more expansive and fulfilling life is just a
                        few clicks away.
                    </Typography>

                    <Box sx={{ mt: 4 }}>
                        <Recommendations sectionScores={sectionScores} archetype="Defender" />
                    </Box>
                </Container>
            </Container>

            <FeedbackForm open={showFeedbackForm} onClose={() => setShowFeedbackForm(false)} />
        </Box>
    );
}