// UserAuthData.js

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import api from "../api";

export default function UserAuthData() {
  const navigate = useNavigate();
  const { userData: contextUserData, setUserData: setContextUserData } = useUser();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const idToken = localStorage.getItem("idToken");
      if (idToken) {
        try {
          const response = await api.get("/api/users/info", {
            params: {
              idToken: idToken,
            },
          });
          if (response && response.status !== 500) {
            setContextUserData(response.data.user);
          }
        } catch (error) {
          console.log(error.response);
          setContextUserData(null);
        }
      } else {
        setContextUserData(null);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [navigate, setContextUserData]);

  return { userData: contextUserData, isLoading };
}
