// src/components/AssessmentSection.js
import React from 'react';
import { Box } from '@mui/material';
import Question from './Question';

const AssessmentSection = ({ section, answers, onAnswer, currentSection }) => {
  if (!section || !section.questions) {
    return null;
  }

  return (
    <Box sx={{ marginBottom: 4 }}>
      {section.questions.map((q, qIndex) => (
        <Question
          key={qIndex}
          question={q.question}
          type={q.type}
          answer={answers[q.question]?.answer}
          onAnswer={(answer) => onAnswer(currentSection, q.question, answer)}
          isActive={true}
        />
      ))}
    </Box>
  );
};

export default AssessmentSection;
