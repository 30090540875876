import React, { useEffect, useState, useCallback } from 'react';
import { Container, Typography, Grid, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserAuthData from '../components/UserAuthData';
import FeaturedBlog from '../components/Blog/FeaturedBlog';
import BlogCard from '../components/Blog/BlogCard';
import CategoryFilter from '../components/Blog/CategoryFilter';
import api from '../api';
import "../styles/styles.css";
import "../styles/tokens.css";

const BlogPage = () => {
    const { userData, isLoading: userLoading } = UserAuthData();
    const [loading, setLoading] = useState(true);
    const [blogs, setBlogs] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('View all');
    const navigate = useNavigate();

    const fetchBlogs = useCallback(async () => {
        try {
            const response = await api.get('/api/blogs');
            setBlogs(response.data.blogs);
        } catch (error) {
            console.error("Error fetching blogs:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchBlogs();
    }, [fetchBlogs]);

    const handleCreateBlog = useCallback(() => {
        if (!userData) {
            navigate('/login');
            return;
        }
        navigate('/create-blog');
    }, [userData, navigate]);

    const handleCategoryChange = useCallback((category) => {
        setSelectedCategory(category);
    }, []);

    if (loading || userLoading) {
        return (
            <Container sx={{ 
                paddingTop: '80px', 
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '50vh'
            }}>
                <CircularProgress color="inherit" />
            </Container>
        );
    }

    // Filter blogs based on the selected category
    const filteredBlogs = selectedCategory === 'View all'
        ? blogs
        : blogs.filter((blog) => blog.category === selectedCategory);

    return (
        <Box sx={{ 
            paddingTop: '8rem', 
            backgroundColor: '#000000', 
            minHeight: '100vh', 
            paddingBottom: '2.5rem', 
            color: 'white' 
        }}>
            <Container sx={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                {/* Header Section */}
                <Box sx={{ 
                    textAlign: 'left', 
                    marginBottom: '1rem', 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'flex-start' 
                }}>
                    <Box>
                        <Typography variant="subtitle2" sx={{ color: 'gray' }}>Blog</Typography>
                        <Typography 
                            variant="h2" 
                            component="h1" 
                            sx={{ fontWeight: 'bold', color: 'var(--accent-color)' }}
                        >
                            Synergistic Intelligence Blogs
                        </Typography>
                        <Typography 
                            variant="subtitle1" 
                            sx={{ color: '#FFC107', marginTop: '0.5rem', marginBottom: '4.5rem' }}
                        > 
                            Unlock Your True Potential
                        </Typography>
                    </Box>
                </Box>

                {/* Featured Blog Section */}
                <FeaturedBlog blog={blogs[0]} />

                {/* Category Filter */}
                <CategoryFilter 
                    selectedCategory={selectedCategory}
                    onCategoryChange={handleCategoryChange}
                    isAdmin={userData?.role === 'admin'}
                    onCreateBlog={handleCreateBlog}
                />

                {/* Blog Cards Grid */}
                <Grid container spacing={4} sx={{ marginTop: '2rem' }}>
                    {filteredBlogs.map((post) => (
                        <Grid item key={post._id} xs={12} sm={6} md={4} sx={{ marginBottom: '3.5rem' }}>
                            <BlogCard post={post} />
                        </Grid>
                    ))}
                    {filteredBlogs.length === 0 && (
                        <Grid item xs={12}>
                            <Typography 
                                variant="body1" 
                                sx={{ 
                                    color: 'white', 
                                    textAlign: 'center',
                                    padding: '2rem'
                                }}
                            >
                                No blog posts available for this category.
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </Box>
    );
};

export default BlogPage;
