// ReplyPost.js
import React,  {useState, useEffect} from "react";
import { Box, Typography } from "@mui/material";
import api from "../../api";

const ReplyPost = ({ reply }) => {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const response = await api.get(
          `/api/forum/author?id=${reply.author}`
        );
        if (response.status === 200) {
          setUserName(response.data.author);
        }
      } catch (error) {
        console.error("Error fetching user name:", error);
      }
    };
    fetchUserName();
  }, [reply.author])
  return (
    <Box sx={{ backgroundColor: "#e0e0e0", borderRadius: "10px", padding: "10px", marginBottom: "10px", marginLeft: "20px" }}>
      <Typography variant="body2" color="black">{reply.content}</Typography>
      <Typography variant="caption" color="textSecondary">
        Posted by {userName} on {new Date(reply.date).toLocaleDateString()}
      </Typography>
    </Box>
  );
};

export default ReplyPost;
