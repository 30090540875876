import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Button,
  Typography,
  Container,
  useTheme,
  useMediaQuery,
  Snackbar,
} from "@mui/material";
import { Instagram, LinkedIn, YouTube, Email } from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";
import { Link } from "react-router-dom";

const FooterLink = ({ to, children }) => (
  <Typography
    component={Link}
    to={to}
    sx={{
      display: 'block',
      color: '#9E9E9E',
      textDecoration: 'none',
      mb: 1.5,
      transition: 'color 0.3s ease',
      '&:hover': {
        color: '#B388FF',
      },
    }}
  >
    {children}
  </Typography>
);

const SocialButton = ({ icon, href, label }) => (
  <IconButton
    component="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={label}
    sx={{
      color: '#9E9E9E',
      transition: 'all 0.3s ease',
      '&:hover': {
        color: '#B388FF',
        transform: 'translateY(-2px)',
      },
    }}
  >
    {icon}
  </IconButton>
);

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSubscribe = (e) => {
    e.preventDefault();
    setSnackbarOpen(true);
  };

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#0A0A0A',
        color: 'white',
        pt: { xs: 6, md: 8 },
        pb: { xs: 4, md: 6 },
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '1px',
          background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
        },
      }}
    >
      <Container maxWidth="lg">
        <Grid 
          container 
          spacing={isMobile ? 3 : 6}
          sx={{ 
            py: isMobile ? 4 : 6,
            px: isMobile ? 2 : 4 
          }}
        >
          {/* Logo and Description */}
          <Grid item xs={12} md={4}>
            <Box sx={{ mb: isMobile ? 3 : 4 }}>
              <Link
                to="/"
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/logo76.png"}
                  alt="Logo"
                  style={{ 
                    height: '40px',
                    marginRight: '12px',
                    filter: 'brightness(1.2)',
                  }}
                />
                <Box>
                  <Typography 
                    sx={{ 
                      fontSize: '1.2rem',
                      color: 'white',
                      fontWeight: 500,
                      lineHeight: 1.2,
                    }}
                  >
                    Synergistic
                    <br />
                    Intelligence
                  </Typography>
                </Box>
              </Link>
            </Box>
            <Typography
              sx={{
                color: '#9E9E9E',
                mb: 3,
                maxWidth: '300px',
                fontSize: '0.9rem',
                lineHeight: 1.6,
              }}
            >
              Empowering human potential through the synergy of consciousness and artificial intelligence.
            </Typography>
            <Box sx={{ mb: 4 }}>
              <SocialButton
                icon={<LinkedIn />}
                href="https://www.linkedin.com/in/stancross111/"
                label="LinkedIn"
              />
              <SocialButton
                icon={<XIcon />}
                href="https://twitter.com"
                label="Twitter"
              />
              <SocialButton
                icon={<Instagram />}
                href="https://instagram.com"
                label="Instagram"
              />
              <SocialButton
                icon={<YouTube />}
                href="https://youtube.com"
                label="YouTube"
              />
            </Box>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={6} sm={4} md={2}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600, 
                mb: 3,
                fontSize: '1rem',
                color: '#E0E0E0',
              }}
            >
              Quick Links
            </Typography>
            <FooterLink to="/">Home</FooterLink>
            <FooterLink to="/about">About Us</FooterLink>
            <FooterLink to="/contact">Contact</FooterLink>
            <FooterLink to="/blog">Blog</FooterLink>
            <FooterLink to="/faqs">FAQs</FooterLink>
          </Grid>

          {/* Resources */}
          <Grid item xs={6} sm={4} md={2}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600, 
                mb: 3,
                fontSize: '1rem',
                color: '#E0E0E0',
              }}
            >
              Resources
            </Typography>
            <FooterLink to="/guides">Guides</FooterLink>
            <FooterLink to="/case-studies">Case Studies</FooterLink>
            <FooterLink to="/webinars">Webinars</FooterLink>
            <FooterLink to="/ebooks">E-books</FooterLink>
            <FooterLink to="/templates">Templates</FooterLink>
          </Grid>

          {/* Newsletter */}
          <Grid item xs={12} sm={8} md={4}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600, 
                mb: 3,
                fontSize: '1rem',
                color: '#E0E0E0',
              }}
            >
              Stay Updated
            </Typography>
            <Typography
              sx={{
                color: '#9E9E9E',
                mb: 2,
                fontSize: '0.9rem',
              }}
            >
              Subscribe to our newsletter for the latest insights and updates.
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubscribe}
              sx={{
                display: 'flex',
                gap: 1,
                mb: 2,
              }}
            >
              <TextField
                placeholder="Enter your email"
                variant="outlined"
                size="small"
                sx={{
                  flex: 1,
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    color: 'white',
                    '& fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.1)',
                    },
                    '&:hover fieldset': {
                      borderColor: '#B388FF',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#B388FF',
                    },
                  },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                startIcon={<Email />}
                sx={{
                  backgroundColor: '#B388FF',
                  '&:hover': {
                    backgroundColor: '#9B6BFF',
                  },
                }}
              >
                Subscribe
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* Bottom Bar */}
        <Box
          sx={{
            mt: { xs: 4, md: 6 },
            pt: 3,
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'center', sm: 'center' },
            gap: 2,
          }}
        >
          <Typography
            sx={{
              color: '#9E9E9E',
              fontSize: '0.85rem',
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            &copy; {new Date().getFullYear()} Synergistic Intelligence. All rights reserved.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 3,
              color: '#9E9E9E',
              fontSize: '0.85rem',
            }}
          >
            <Link to="/privacy" style={{ color: 'inherit', textDecoration: 'none' }}>
              Privacy Policy
            </Link>
            <Link to="/terms" style={{ color: 'inherit', textDecoration: 'none' }}>
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Box
          sx={{
            backgroundColor: '#B388FF',
            padding: '12px 24px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0 4px 12px rgba(179, 136, 255, 0.3)',
          }}
        >
          <Typography
            sx={{
              color: '#000000',
              fontWeight: 500,
            }}
          >
            Newsletter subscription feature is under development
          </Typography>
        </Box>
      </Snackbar>
    </Box>
  );
};

export default Footer;
