import React, { useEffect, useState } from "react";
import { Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button} from "@mui/material";
import UserAuthData from "../components/UserAuthData";
import DiscussionPost from "../components/Discussion/DiscussionPost";
import NewDiscussionPost from "../components/Discussion/NewDiscussionPost";
import api from "../api";

const CommunityPage = ({ pageId, title }) => {

  const { userData } = UserAuthData();
  const [discussions, setDiscussions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);



  useEffect(() => {
    const fetchDiscussions = async () => {
      try {
        const response = await api.get(`/api/pages/${pageId}/discussions`);
        setDiscussions(response.data.discussions);
      } catch (error) {
        console.error("Error fetching discussions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDiscussions();
  }, [pageId]);

  const handleAddDiscussion = async (content) => {
    if (!userData){
      setOpenPopup(true);
    }
    else{
    try {
      const newDiscussion = {
        content,
        author: userData._id, 
        date: new Date(),
      };
      const response = await api.post(`/api/pages/${pageId}/discussions`, newDiscussion);
      setDiscussions([...discussions, response.data.added_discussion]);
    } catch (error) {
      console.error("Error adding discussion:", error);
    }
  }
  };

  const handleAddReply = async (discussionId, replyContent) => {
    if (!userData){
      setOpenPopup(true)
    }
    else{
    try {
      const newReply = {
        content: replyContent,
        author: userData._id, // Replace with actual user data
        date: new Date(),
        discussionId,
      };
      const response = await api.post(`/api/discussions/${discussionId}/replies`, newReply);
      const updatedDiscussions = discussions.map((discussion) => {
        if (discussion._id === discussionId) {
          return {
            ...discussion,
            replies: [...discussion.replies, response.data.added_reply],
          };
        }
        return discussion;
      });
      setDiscussions(updatedDiscussions);
    } catch (error) {
      console.error("Error adding reply:", error);
    }
  }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };


  // if (authLoading) {
  //   return <div>Loading...</div>;
  // }
  // if(!userData){
  //   return <div>Please Log In to View Discussions</div>;
  // }
  return (
    <Box>
      <Typography variant="h4" sx={{ color: "var(--accent-color)", marginBottom: 2 }}>
        {title}
      </Typography>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Box>
          {discussions.map((discussion) => (
            <DiscussionPost key={discussion._id} discussion={discussion} onAddReply={handleAddReply} />
          ))}
          <NewDiscussionPost onAddDiscussion={handleAddDiscussion} />
        </Box>
        
      )}
      <Dialog open={openPopup} onClose={handleClosePopup}>
        <DialogTitle>Login Required</DialogTitle>
        <DialogContent>
          <p>Please Log In to Post Discussions or Replies</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CommunityPage;
