export const assessmentResources = [
  {
    image: "/images/stagnant_identity.jpeg",
    altText: "Breaking free from stagnant identity",
    title: "Breaking Free: Understanding Stagnant Identity",
    description: 'Discover how your inherited survival patterns have shaped your identity and learn the first steps to breaking free from these limiting beliefs. Begin your journey of transformation.',
    link: "/editable-page/SI/Understanding Stagnant Identity",
  },
  {
    image: "/images/survival_inst.jpeg",
    altText: "Transcending survival instincts",
    title: "Transcending Survival Instincts",
    description: 'Explore how survival-based thinking limits your potential and learn powerful techniques to shift from survival mode to a state of creative abundance and possibility.',
    link: "/editable-page/SI/Exploring Survival Instincts",
  },
  {
    image: "/images/defender.png",
    altText: "Transforming ego defenses",
    title: "Transforming Ego Defenses",
    description: 'Learn to observe and understand your defensive patterns with compassion. Through mindful awareness, transform these barriers into stepping stones for growth.',
    link: "/editable-page/SI/Recognizing Ego Defensiveness",
  },
  {
    image: "/images/flow_ident.jpeg",
    altText: "Embracing flow identity",
    title: "Embracing Flow Identity",
    description: 'Take the courageous leap from surviving to thriving. Discover how releasing the need for control paradoxically opens the door to greater creativity and authentic living.',
    link: "/editable-page/SI/Shifting Towards Flow Identity",
  },
  {
    image: "/images/cultivating_openess.jpeg",
    altText: "Cultivating openness",
    title: "The Art of Openness",
    description: 'Explore profound insights from wisdom traditions and modern psychology about embracing uncertainty. Learn practical techniques for developing greater presence and receptivity.',
    link: "/editable-page/SI/Cultivating Openness and Awareness",
  },
  {
    image: "/images/deep_reflection.jpeg",
    altText: "Deep personal reflection",
    title: "Integrating Your Journey",
    description: 'Synthesize your learning through deep personal reflection. Discover tools and practices for maintaining presence and authenticity in daily life while navigating your ongoing evolution.',
    link: "/editable-page/SI/Deeper Personal Reflection",
  },
];
