import React, { useState, useCallback } from "react";
import { Typography, Grid, Box, Container, Fade } from "@mui/material";
import ResourceCard from "./ResourceCard";
import OutlinedButton from "../common/OutlinedButton";
import { assessmentResources } from "../../data/assessmentResources";
import "../../styles/styles.css";
import "../../styles/tokens.css";

const AdditionalAssessment = () => {
  const [cardsToShow, setCardsToShow] = useState(4);
  const [showButton, setShowButton] = useState(true);

  const handleViewAllClick = useCallback(() => {
    setCardsToShow(assessmentResources.length);
    setShowButton(false);
  }, []);

  return (
    <Container maxWidth="xl" sx={{ backgroundColor: 'black' }}>
      <Box 
        sx={{ 
          flexGrow: 1, 
          py: { xs: 6, md: 8 },
          px: { xs: 2, sm: 4, md: 5 },
          minHeight: '100vh',
          position: 'relative'
        }}
      >
        <Box sx={{ 
          maxWidth: '800px', 
          mx: 'auto', 
          mb: 8,
          textAlign: 'center' 
        }}>
          <Typography 
            variant="h2" 
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '2.5rem', sm: '3rem' },
              background: 'linear-gradient(45deg, var(--accent-color) 30%, #fff 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 3
            }}
          >
            Your Path to Transformation
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: 'rgba(255, 255, 255, 0.8)',
              maxWidth: '600px',
              mx: 'auto',
              lineHeight: 1.6
            }}
          >
            Explore these carefully curated resources designed to support your journey from survival-based living to creative thriving.
          </Typography>
        </Box>

        <Grid 
          container 
          spacing={{ xs: 3, md: 4 }} 
          sx={{ 
            px: { xs: 1, sm: 3, md: 7 },
            mb: 4
          }}
        >
          {assessmentResources.slice(0, cardsToShow).map((resource, index) => (
            <Fade in={true} timeout={500 + index * 200}>
              <Grid 
                item 
                xs={12} 
                sm={6} 
                md={6} 
                key={index}
                sx={{ 
                  display: 'flex',
                  transform: 'translateY(0)',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                  }
                }}
              >
                <ResourceCard {...resource} />
              </Grid>
            </Fade>
          ))}
        </Grid>

        {showButton && (
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              mt: 6
            }}
          >
            <OutlinedButton 
              onClick={handleViewAllClick}
              sx={{ 
                minWidth: '200px',
                fontSize: '1.1rem',
                py: 1.5
              }}
            >
              Explore More Resources
            </OutlinedButton>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default AdditionalAssessment;
