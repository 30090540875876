import React, { useEffect } from "react";
import { Typography, Box, Container, useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import "../styles/styles.css";
import "../styles/tokens.css";

const StepComponent = ({ number, title, description, path, index }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const wavePathId = `wavePathVertical-${index}`;

  return (
    <motion.div
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      whileHover={{ 
        scale: isMobile ? 1.01 : 1.02,
        transition: { duration: 0.3 }
      }}
    >
      <Box
        onClick={() => navigate(path)}
        component="article"
        role="button"
        tabIndex={0}
        onKeyPress={(e) => e.key === 'Enter' && navigate(path)}
        aria-label={`Step ${number}: ${title}`}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: isMobile ? '15px' : '20px',
          padding: isMobile ? '20px 15px' : '40px 20px',
          cursor: 'pointer',
          position: 'relative',
          borderRadius: '12px',
          transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
          background: 'linear-gradient(145deg, rgba(179, 136, 255, 0.02), rgba(0, 0, 0, 0))',
          backdropFilter: 'blur(5px)',
          overflow: 'hidden',
          '&:hover': {
            background: 'linear-gradient(145deg, rgba(179, 136, 255, 0.05), rgba(0, 0, 0, 0))',
            '& .step-content': {
              transform: 'translateX(10px)',
            },
            '& .step-number': {
              transform: 'scale(1.1) rotate(360deg)',
              boxShadow: '0 0 30px rgba(179, 136, 255, 0.3)',
              borderColor: '#B388FF',
            },
            '& .title': {
              color: '#B388FF',
              textShadow: '0 0 10px rgba(179, 136, 255, 0.3)',
            },
            '& .wave-container': {
              opacity: 1,
              transform: 'translateX(0)',
              visibility: 'visible',
            }
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            borderRadius: '12px',
            padding: '1px',
            background: 'linear-gradient(145deg, rgba(179, 136, 255, 0.1), transparent)',
            WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude',
          },
        }}
      >
        <Box
          className="wave-container"
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: '60px',
            height: '100%',
            opacity: 0,
            visibility: 'hidden',
            transition: 'all 0.5s ease',
            overflow: 'hidden',
          }}
        >
          {[...Array(4)].map((_, i) => (
            <Box
              key={i}
              className="wave"
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                width: '100%',
                height: '100%',
                opacity: 0.5,
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: '-100%',
                  right: '0',
                  width: '100%',
                  height: '200%',
                  background: `linear-gradient(180deg, transparent, ${
                    i === 0 ? 'rgba(179, 136, 255, 0.6)' : // Purple
                    i === 1 ? 'rgba(102, 204, 255, 0.6)' : // Blue
                    i === 2 ? 'rgba(147, 255, 199, 0.6)' : // Green
                    'rgba(255, 138, 138, 0.6)'             // Red
                  }, transparent)`,
                  clipPath: `url(#${wavePathId})`,
                  animation: 'waveFlowVertical 3s linear infinite',
                  animationDelay: `${i * -0.5}s`,
                  filter: 'blur(3px)',
                },
                '@keyframes waveFlowVertical': {
                  '0%': {
                    transform: 'translateY(0%)',
                  },
                  '100%': {
                    transform: 'translateY(50%)',
                  }
                }
              }}
              style={{
                right: `${i * 15}px`
              }}
            />
          ))}
          <svg width="0" height="0">
            <defs>
              <clipPath id={wavePathId}>
                <path d="M30,0 
                  Q0,50 30,100 
                  T30,200 
                  T30,300
                  T30,400
                  H60 V0 Z" />
              </clipPath>
            </defs>
          </svg>
          <Box
            sx={{
              position: 'absolute',
              inset: 0,
              background: 'radial-gradient(circle at right, rgba(179, 136, 255, 0.05), transparent 70%)',
              filter: 'blur(8px)',
            }}
          />
        </Box>
        <Box
          className="step-number"
          sx={{
            width: { xs: '40px', md: '50px' },
            height: { xs: '40px', md: '50px' },
            borderRadius: '50%',
            backgroundColor: 'rgba(179, 136, 255, 0.1)',
            border: '2px solid rgba(179, 136, 255, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
            position: 'relative',
            zIndex: 1,
            transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
            animation: 'pulseGlow 2s infinite',
          }}
        >
          <Typography 
            sx={{ 
              color: '#B388FF', 
              fontWeight: 'bold',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.1)',
              }
            }}
          >
            {number}
          </Typography>
        </Box>
        <Box 
          className="step-content"
          sx={{ 
            transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
            flex: 1,
            marginLeft: '20px',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <motion.div
            whileHover={{ scale: 1.01 }}
            transition={{ duration: 0.2 }}
          >
            <Typography 
              className="title"
              variant={isMobile ? "h6" : "h5"}
              sx={{ 
                color: '#fff',
                marginBottom: isMobile ? '0.5rem' : '1rem',
                transition: 'color 0.3s ease',
              }}
            >
              {title}
            </Typography>
          </motion.div>
          <motion.div
            whileHover={{ x: 5 }}
            transition={{ duration: 0.2 }}
          >
            <Typography 
              sx={{ 
                color: '#999',
                fontSize: isMobile ? '0.8rem' : '0.9rem',
                lineHeight: '1.8',
                transition: 'color 0.3s ease',
                paddingRight: '70px', // Space for the wave animation
                '&:hover': {
                  color: '#E0E0E0',
                },
              }}
            >
              {description}
            </Typography>
          </motion.div>
        </Box>
      </Box>
    </motion.div>
  );
};

const HeroSection = () => {
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      sx={{
        background: 'linear-gradient(180deg, rgba(179, 136, 255, 0.15) 0%, rgba(0, 0, 0, 0) 100%)',
        padding: { xs: '40px 0', md: '60px 0' },
        paddingTop: { xs: '100px', md: '120px' }, 
        textAlign: 'left',
        marginBottom: { xs: '20px', md: '40px' }, 
        borderRadius: '0 0 30px 30px',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '1px',
          background: 'linear-gradient(90deg, #B388FF, transparent)',
        },
      }}
    >
      <Container maxWidth="md" sx={{ pl: { xs: 3, md: 4 } }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.6 }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }, 
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #B388FF, #80CBC4)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
              marginBottom: '16px', 
              textTransform: 'uppercase',
              letterSpacing: '3px',
              textShadow: '0 0 30px rgba(179, 136, 255, 0.3)',
            }}
          >
            Flow Journey
          </Typography>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.6 }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '1.1rem', md: '1.25rem' }, 
              color: '#9E9E9E',
              marginBottom: '24px', 
              fontWeight: '300',
              letterSpacing: '1px',
            }}
          >
            Transcend Limitations, Embrace Evolution
          </Typography>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7, duration: 0.6 }}
        >
          <Typography
            sx={{
              fontSize: { xs: '0.9rem', md: '1rem' }, 
              color: '#E0E0E0',
              maxWidth: '800px',
              margin: '0',
              lineHeight: '1.7', 
              padding: { xs: '0', md: 0 },
            }}
          >
            Embark on a transformative journey from survival-based consciousness to fluid, adaptive intelligence. 
            This path reveals how we can transcend our innate survival mechanisms, release stagnant identities, 
            and cultivate a state of perpetual flow. Through this evolution, we unlock unprecedented levels of 
            creativity, innovation, and collective intelligence in harmony with emerging AI technologies.
          </Typography>
        </motion.div>
      </Container>
    </Box>
  );
};

const FlowJourney = () => {
  const steps = [
    {
      number: '1',
      title: 'Survival Instinct Paradox',
      description: 'The Survival Instinct Paradox lies in the contradiction that the instinct designed to protect and sustain us often hinders our growth and adaptability. While essential for physical safety, this instinct extends into psychological realms, prompting us to cling to identities, beliefs, and patterns that feel secure but ultimately limit us.',
      path: '/survival-instinct-paradox'
    },
    {
      number: '2',
      title: 'Stagnant Identity',
      description: 'A stagnant identity is the fixed sense of self shaped by the survival instinct, built from past experiences, cultural conditioning, and fears. It resists change to maintain a facade of stability and control, confining individuals to repetitive thought patterns and behaviors.',
      path: '/stagnant-identity'
    },
    {
      number: '3',
      title: 'Letting Go',
      description: "Letting go is the act of releasing the psychological grip on control, certainty, and the desire to preserve one's identity at all costs. It requires acknowledging the illusion of permanence and embracing the impermanence of life.",
      path: '/letting-go'
    },
    {
      number: '4',
      title: 'Flow State',
      description: 'A flow state is a temporary condition of total immersion and alignment with the present moment, where effort feels effortless, and action flows seamlessly from intuition and skill. In this state, the survival instinct is quieted, and the mind and body operate as a unified system.',
      path: '/flow-state'
    },
    {
      number: '5',
      title: 'Flow Identity',
      description: "Flow identity is a consistent embodiment of the flow state, where openness, adaptability, and creativity become intrinsic to one's way of being. It transcends the survival instinct and stagnant identity by fostering a sense of connection to the larger whole.",
      path: '/flow-identity'
    },
    {
      number: '6',
      title: 'Synergistic Intelligence',
      description: "Synergistic Intelligence is the collective intelligence that emerges when individuals with flow identities collaborate with each other and AI tools like Portal AI. It integrates human intuition, creativity, and emotional intelligence with AI's analytical power and holistic perspective.",
      path: '/synergistic-intelligence'
    }
  ];

  useEffect(() => {
    // Smooth scroll to top when component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        minHeight: '100vh',
        backgroundColor: '#000000',
        paddingBottom: { xs: '40px', md: '80px' },
      }}
    >
      <HeroSection />
      <Box 
        sx={{ 
          maxWidth: '800px', 
          margin: '0 auto', 
          padding: '0 20px',
          ml: { xs: 3, md: 4 },
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: '40px',
            width: '2px',
            background: 'rgba(179, 136, 255, 0.1)',
            zIndex: 0,
          }
        }}
      >
        {steps.map((step, index) => (
          <StepComponent
            key={step.number}
            number={step.number}
            title={step.title}
            description={step.description}
            path={step.path}
            index={index}
          />
        ))}
      </Box>
    </Container>
  );
};

export default FlowJourney;
