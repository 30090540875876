import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { stripHtmlTags } from '../../utils/textUtils';

const FeaturedBlog = ({ blog }) => {
    if (!blog) return null;

    return (
        <Link to={`/blog/${blog._id}`} style={{ textDecoration: 'none' }}>
            <Grid 
                container 
                spacing={4} 
                sx={{ 
                    marginBottom: '2rem', 
                    cursor: 'pointer', 
                    transition: 'transform 0.3s, box-shadow 0.3s', 
                    '&:hover': { 
                        transform: 'scale(1.02)', 
                        boxShadow: '0 0.5rem 1.5rem rgba(0,0,0,0.3)' 
                    },
                    alignItems: 'center',
                    paddingLeft: '1.5rem', 
                }}
            >
                <Grid item xs={12} md={6}>
                    <img 
                        src={blog.image} 
                        alt={blog.title} 
                        style={{ 
                            width: '100%', 
                            height: 'auto', 
                            maxWidth: '35vw', 
                            maxHeight: '55vh', 
                            borderRadius: '0.625rem', 
                            marginRight: '1.5rem' 
                        }} 
                    />
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    md={6} 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center',
                        paddingLeft: '1.5rem' 
                    }}
                >
                    <Typography variant="subtitle1" sx={{ color: 'gray' }}>{blog.category}</Typography>
                    <Typography variant="h4" component="h2" sx={{ color: '#FFFFFF', fontWeight: 'bold', marginBottom: '1rem' }}>
                        {blog.title}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#FFFFFF', marginBottom: '1rem' }}>
                        {stripHtmlTags(blog.content).slice(0, 100) + "..."}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'gray' }}>
                        {blog.author} • {new Date(blog.date).toLocaleDateString()} • 5 min read
                    </Typography>
                </Grid>
            </Grid>
        </Link>
    );
};

export default FeaturedBlog;
