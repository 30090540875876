import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import "../../styles/styles.css";

const ResourceCard = ({ image, altText, title, description, link }) => {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate(link)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        cursor: 'pointer',
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        transition: 'all 0.3s ease-in-out',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          '& .card-media': {
            transform: 'scale(1.05)',
          },
          '& .card-overlay': {
            opacity: 0.7,
          }
        },
      }}
    >
      <Box sx={{ position: 'relative', pt: '56.25%' }}>
        <CardMedia
          component="img"
          image={image}
          alt={altText}
          className="card-media"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            transition: 'transform 0.6s ease-in-out',
          }}
        />
        <Box
          className="card-overlay"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.8) 100%)',
            opacity: 0.5,
            transition: 'opacity 0.3s ease-in-out',
          }}
        />
      </Box>
      <CardContent
        sx={{
          flexGrow: 1,
          backgroundColor: 'transparent',
          position: 'relative',
          zIndex: 1,
          p: 3,
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            color: 'white',
            fontWeight: 600,
            mb: 2,
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
            lineHeight: 1.3,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'rgba(255, 255, 255, 0.8)',
            fontSize: { xs: '0.9rem', sm: '1rem' },
            lineHeight: 1.6,
          }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ResourceCard;
