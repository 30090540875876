import { Box, Typography, Container, Button, Paper } from '@mui/material';
import { useState } from 'react';
import FeedbackForm from '../FeedbackForm';

export default function Innovator({name, handleRetakeAssessment}){
    const [showFeedbackForm, setShowFeedbackForm] = useState(false); 

    return (
        <Box sx={{ background: 'var(--background-color)', py: 4 }}>
            <Container maxWidth="md">
                <Paper elevation={3} sx={{ 
                    p: 4, 
                    borderRadius: 2, 
                    mb: 3,
                    backgroundColor: '#0A0A0A',
                    color: 'white',
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '1px',
                        background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
                    }
                }}>
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}>
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h3" component="h1" sx={{ 
                                color: '#B388FF',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 2
                            }}>
                                The Innovator <span role="img" aria-label="innovator">⭐️</span>
                            </Typography>
                            
                            <Typography variant="h4" component="h2" sx={{ mb: 2, color: 'white' }}>
                                Welcome, {name}!
                            </Typography>

                            <Typography variant="body1" sx={{ mb: 3, fontSize: '1.2rem', lineHeight: 1.6, color: '#9E9E9E' }}>
                                Congratulations on completing the Survival Instinct Awareness Assessment! Your results reveal that you are an <Box component="span" sx={{ fontWeight: 'bold', color: '#B388FF' }}>Innovator</Box> - someone who thrives on creativity and leads the way in transformative change.
                            </Typography>
                        </Box>

                        <Box sx={{ 
                            display: 'grid', 
                            gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, 
                            gap: 4, 
                            mb: 4 
                        }}>
                            <Box sx={{ 
                                p: 3, 
                                borderRadius: 2, 
                                backgroundColor: '#0A0A0A',
                                border: '1px solid #B388FF',
                                color: 'white',
                                position: 'relative',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    height: '1px',
                                    background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
                                }
                            }}>
                                <Typography variant="h6" sx={{ mb: 2, color: '#B388FF' }}>
                                    Your Core Strengths
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    {['Free & Present', 'Purposeful', 'Determined', 'Fearless', 'Risk-taker'].map((strength, index) => (
                                        <Typography key={index} sx={{ 
                                            color: '#9E9E9E',
                                            display: 'flex',
                                            alignItems: 'center',
                                            transition: 'color 0.3s ease',
                                            '&:hover': {
                                                color: '#B388FF',
                                            },
                                            '&:before': {
                                                content: '"•"',
                                                color: '#B388FF',
                                                mr: 1,
                                                fontWeight: 'bold'
                                            }
                                        }}>
                                            {strength}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>

                            <Box sx={{ 
                                p: 3, 
                                borderRadius: 2, 
                                backgroundColor: '#0A0A0A',
                                border: '1px solid #B388FF',
                                color: 'white',
                                position: 'relative',
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    height: '1px',
                                    background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
                                }
                            }}>
                                <Typography variant="h6" sx={{ mb: 2, color: '#B388FF' }}>
                                    Growth Opportunities
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    {['Balance', 'Patience', 'Structured planning', 'Risk assessment'].map((area, index) => (
                                        <Typography key={index} sx={{ 
                                            color: '#9E9E9E',
                                            display: 'flex',
                                            alignItems: 'center',
                                            transition: 'color 0.3s ease',
                                            '&:hover': {
                                                color: '#B388FF',
                                            },
                                            '&:before': {
                                                content: '"•"',
                                                color: '#B388FF',
                                                mr: 1,
                                                fontWeight: 'bold'
                                            }
                                        }}>
                                            {area}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{ 
                            display: 'flex', 
                            gap: 2,
                            justifyContent: 'center',
                            mt: 2
                        }}>
                            <Button
                                variant="contained"
                                onClick={handleRetakeAssessment}
                                sx={{
                                    backgroundColor: '#B388FF',
                                    color: 'white',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#9B6BFF',
                                        transform: 'translateY(-2px)',
                                    },
                                    px: 4
                                }}
                            >
                                Retake Assessment
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => setShowFeedbackForm(true)}
                                sx={{
                                    color: '#B388FF',
                                    borderColor: '#B388FF',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        borderColor: '#9B6BFF',
                                        backgroundColor: 'rgba(179, 136, 255, 0.1)',
                                        transform: 'translateY(-2px)',
                                    },
                                    px: 4
                                }}
                            >
                                Share Feedback
                            </Button>
                        </Box>
                    </Box>
                </Paper>

                <Container maxWidth="md" sx={{ 
                    backgroundColor: '#0A0A0A', 
                    color: 'white', 
                    p: 4, 
                    borderRadius: 2, 
                    boxShadow: 3, 
                    marginBottom: '1rem',
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '1px',
                        background: 'linear-gradient(90deg, transparent, #B388FF, transparent)',
                    }
                }}>
                    <Typography variant="h4" sx={{ fontWeight:'bold', mb: 2, color: '#B388FF' }}>
                        Archetype: The Innovator
                    </Typography>

                    <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                        <img
                            src={process.env.PUBLIC_URL + '/images/innovator.png'}
                            alt="innovator"
                            style={{
                                height: "80%",
                                width: "45%",
                                filter: 'brightness(1.2)',
                            }}
                        />

                        <Box sx={{ ml: 3, mt: 5 }}>
                            <Typography variant="body1" sx={{fontSize: '1.1rem', color: '#9E9E9E'}}>
                                <strong style={{ color: '#B388FF' }}>Key Traits:</strong> Free & Present, Purposeful, Determined, Fearless, Risk-taker
                            </Typography>

                            <Typography variant="body1" sx={{fontSize: '1.1rem', mt: 3, color: '#9E9E9E' }}>
                                <strong style={{ color: '#B388FF' }}>Your Journey:</strong> You've cultivated an open mind and learned from mistakes and
                                different perspectives. Your high emotional intelligence allows you to navigate
                                challenges without being held back by negative reactions. You thrive on learning,
                                collaboration, and the excitement of new experiences. Embrace a life of
                                continuous growth and innovation, taking on new challenges with optimism.
                            </Typography>
                        </Box>
                    </Box>

                    <Typography variant="body1" sx={{ mb: 2, mt: 4, fontSize: '1.1rem', color: '#9E9E9E'}}>
                        <strong style={{ color: '#B388FF' }}>Why This Matters: </strong> <br/>
                        You already possess a remarkable openness to change, but there is always room for
                        further growth and refinement. By continuing to explore the depths of your instincts, you
                        can achieve even greater alignment with your purpose and potential. Your journey
                        doesn't end with awareness; it evolves as you use this awareness to innovate, create,
                        and lead. Imagine the transformative impact when you help others to break free from
                        their fears and resistance!

                        <br/><br/>
                        <strong style={{ color: '#B388FF' }}>Keep Exploring: </strong> <br/>
                        Your role as an Innovator is not just to grow yourself but to be a catalyst for others'
                        growth. Dive deeper into resources, explore uncharted areas of your instincts, and
                        engage with our community to foster a culture of co-creation and inspiration. You are in
                        a unique position to shape not only your future but the future of others who look up to
                        you as a guide.

                        <br/><br/>
                        <strong style={{ color: '#B388FF' }}>Intrigued?</strong> <br/>
                        Start a conversation on the SI platform today and share your insights with others.
                        Become a mentor, spark new dialogues, and contribute to a community dedicated to
                        collective growth and innovation. Your journey towards an even more expansive and
                        impactful life is just a few steps away.
                    </Typography>
                </Container>
            </Container>

            <FeedbackForm open={showFeedbackForm} onClose={() => setShowFeedbackForm(false)} />
        </Box>
    );
}