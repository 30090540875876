// ProtectedRoute.js

import { Navigate } from 'react-router-dom';
import UserAuthData from "../components/UserAuthData";

const ProtectedRoute = ({ children }) => {
    const { userData, isLoading } = UserAuthData();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!userData) {
        return <Navigate to="/login" replace />;
    }
    
    return children;
};

export default ProtectedRoute;