import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import api from "../api";
import UserAuthData from "../components/UserAuthData";
import DashNavBar from "../components/DashNavBar";

import "../styles/styles.css";
import "../styles/tokens.css";

const drawerWidth = 300;
const page = "Dashboard";

export default function Dashboard() {
  const { userAuth, isLoading, setUserAuth } = UserAuthData();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userVerticals, setUserVerticals] = useState([]);
  const [loadingVerticals, setLoadingVerticals] = useState(true);

  const capitalizeEachWord = (str) => {
    const words = str.split(" ");
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(" ");
  };

  // Adjust main box component based on window size
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Fetch user's vertical names
  useEffect(() => {
    const fetchVerticalNames = async () => {
      try {
        const response = await api.get(`/api/verticals/user/${userAuth._id}`);
        const verticals = response.data.verticals.map((vertical) => ({
          id: vertical._id,
          name: vertical.vertical,
        }));
        setUserVerticals(verticals);
        setLoadingVerticals(false);
      } catch (error) {
        console.error("Error fetching vertical names:", error);
        setLoadingVerticals(false);
      }
    };

    fetchVerticalNames();
  }, [userAuth]);

  // Add vertical to user
  const handleAddVertical = async (verticalName) => {
    try {
      const response = await api.post(`/api/verticals/user/${userAuth._id}`, {
        vertical: verticalName,
        conversations: [],
      });
      if (response.status === 200) {
        setUserAuth({
          ...userAuth,
          verticals: [...userAuth.verticals, response.data.added_vertical],
        });
        setUserVerticals((prevVerticals) => [
          ...prevVerticals,
          { id: response.data.added_vertical._id, name: verticalName },
        ]);
      }
    } catch (error) {
      alert("Vertical already exists.");
      console.error("Error adding vertical:", error.response);
    }
  };

  const handleDeleteVertical = async (verticalId) => {
    try {
      const response = await api.delete(
        `/api/verticals/user/${userAuth._id}/${verticalId}`
      );
      if (response.status === 200) {
        setUserAuth({
          ...userAuth,
          verticals: userAuth.verticals.filter(
            (vertical) => vertical._id !== verticalId
          ),
        });
        setUserVerticals((prevVerticals) =>
          prevVerticals.filter((vertical) => vertical.id !== verticalId)
        );
      }
    } catch (error) {
      console.error("Error deleting vertical:", error.response);
    }
  };

  if (isLoading || loadingVerticals) {
    return <div>Loading...</div>;
  }

  if (!userAuth) {
    return <div>Please Log In to View</div>
  }

  const availableVerticals = [
    { name: "real estate", displayName: "Add Real Estate" },
    { name: "education", displayName: "Add Education" },
    { name: "politics", displayName: "Add Politics" },
  ];

  const userVerticalNames = userVerticals.map((vertical) => vertical.name);

  const verticalCards = availableVerticals.filter(
    (vertical) => !userVerticalNames.includes(vertical.name)
  );

  return (
    <Box sx={{ position: "relative" }}>
      <DashNavBar userAuth={userAuth} page={page} />
      <Box
        component="main"
        sx={{
          backgroundColor: "var(--primary-color)",
          position: "absolute",
          top: "65px",
          left: windowWidth < 600 ? 0 : drawerWidth,
          width: `calc(100% - ${windowWidth < 600 ? 0 : drawerWidth}px)`,
          minHeight: "100vh",
          p: 3,
        }}
      >
        <Typography
          className="header"
          sx={{ color: "var(--accent-color)", marginBottom: "1rem" }}
        >
          {page}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            flexWrap: "wrap",
          }}
        >
          {userVerticals.map((vertical) => (
            <Card
              key={vertical.id}
              sx={{
                width: "275px",
                height: "300px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#232323",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: "#282828",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography className="grey">
                  {capitalizeEachWord(vertical.name)}
                </Typography>
              </CardContent>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <Tooltip title="Delete Vertical" arrow>
                  <IconButton onClick={() => handleDeleteVertical(vertical.id)}>
                    <DeleteForeverIcon style={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Card>
          ))}
          {verticalCards.map((vertical, index) => (
            <Card
              key={index}
              sx={{
                width: "275px",
                height: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#232323",
                borderRadius: "10px",
                cursor: "pointer",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: "#282828",
                },
              }}
              onClick={() => handleAddVertical(vertical.name)}
            >
              <CardActionArea sx={{ height: "100%" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" color="var(--faded-body-text-color)">
                    +
                  </Typography>
                  <Typography className="grey">
                    {vertical.displayName}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
