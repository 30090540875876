// Login.js

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Alert,
  alpha,
  Container,
  Stack,
  Checkbox,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import StarsIcon from '@mui/icons-material/Stars';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { motion } from "framer-motion";
import api from "../api";

const LinkedInIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="24" 
    height="24" 
    viewBox="0 0 24 24"
    style={{ marginRight: '8px' }}
  >
    <path 
      fill="currentColor" 
      d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"
    />
  </svg>
);

const FloatingElement = ({ children, delay = 0, duration = 2 }) => (
  <motion.div
    style={{
      display: 'inline-block',
    }}
    animate={{
      y: [0, -10, 0],
      rotate: [-2, 2, -2],
    }}
    transition={{
      duration,
      repeat: Infinity,
      delay,
    }}
  >
    {children}
  </motion.div>
);

const OrbitingIcon = ({ Icon, radius = 40, duration = 10, delay = 0, clockwise = true }) => {
  return (
    <motion.div
      style={{
        position: 'absolute',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
      }}
      animate={{
        rotate: clockwise ? 360 : -360,
      }}
      transition={{
        duration,
        repeat: Infinity,
        ease: "linear",
        delay,
      }}
    >
      <motion.div
        style={{
          position: 'absolute',
          top: -radius,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.7, 1, 0.7],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      >
        <Icon sx={{ 
          color: '#ba68c8',
          fontSize: '2rem',
          filter: 'drop-shadow(0 0 10px rgba(186, 104, 200, 0.5))',
        }} />
      </motion.div>
    </motion.div>
  );
};

const QuoteSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, delay: 0.5 }}
      style={{ position: 'relative', zIndex: 2 }}
    >
      <Box sx={{ 
        color: '#fff', 
        mb: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50vh',
        px: 4
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <FloatingElement delay={0.2} duration={4}>
            <AutoAwesomeIcon sx={{ color: '#ba68c8', fontSize: '3rem' }} />
          </FloatingElement>
        </Box>

        <Box sx={{ maxWidth: '800px', width: '100%' }}>
          <FloatingElement delay={0} duration={4}>
            <Typography 
              variant={isMobile ? "h3" : "h2"} 
              sx={{ 
                mb: 2,
                fontSize: isMobile ? '2.2rem' : '3rem',
                fontWeight: 500,
                textAlign: 'center',
                textShadow: '0 0 20px rgba(186, 104, 200, 0.3)',
              }}
            >
              At Synergistic Intelligence,
            </Typography>
          </FloatingElement>

          <FloatingElement delay={0.3} duration={4}>
            <Typography 
              variant={isMobile ? "h3" : "h2"}
              sx={{ 
                mb: 2,
                fontSize: isMobile ? '2.2rem' : '3rem',
                fontWeight: 500,
                textAlign: 'center',
                textShadow: '0 0 20px rgba(186, 104, 200, 0.3)',
              }}
            >
              we specialize in fostering an environment
            </Typography>
          </FloatingElement>

          <FloatingElement delay={0.6} duration={4}>
            <Typography 
              variant={isMobile ? "h3" : "h2"}
              sx={{ 
                fontStyle: 'italic',
                fontSize: isMobile ? '2.2rem' : '3rem',
                fontWeight: 500,
                textAlign: 'center',
                color: '#ba68c8',
                textShadow: '0 0 20px rgba(186, 104, 200, 0.5)',
                position: 'relative',
                '&::before': {
                  content: 'open-quote',
                  marginRight: '0.2em',
                },
                '&::after': {
                  content: 'close-quote',
                  marginLeft: '0.2em',
                }
              }}
            >
              where the whole is greater than sum of its parts
            </Typography>
          </FloatingElement>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <FloatingElement delay={0.9} duration={4}>
            <StarsIcon sx={{ color: '#ba68c8', fontSize: '3rem' }} />
          </FloatingElement>
        </Box>
      </Box>
    </motion.div>
  );
};

const InteractiveScene = () => {
  return (
    <Box sx={{ 
      mt: 4, 
      height: '200px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <motion.div
        style={{
          position: 'relative',
          zIndex: 2,
        }}
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ 
            type: "spring",
            stiffness: 260,
            damping: 20,
            delay: 1 
          }}
        >
          <EmojiPeopleIcon sx={{ 
            fontSize: '6rem', 
            color: '#ba68c8',
            filter: 'drop-shadow(0 0 20px rgba(186, 104, 200, 0.5))',
          }} />
        </motion.div>

        {/* Orbiting icons */}
        <OrbitingIcon Icon={RocketLaunchIcon} radius={60} duration={12} delay={0} />
        <OrbitingIcon Icon={LightbulbIcon} radius={75} duration={15} delay={1} clockwise={false} />
        <OrbitingIcon Icon={SentimentVerySatisfiedIcon} radius={90} duration={18} delay={2} />
      </motion.div>
    </Box>
  );
};

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [userAuth, setUserAuth] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (event) => {
    setUserAuth(prev => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleLoginAccount = async (e, userAuth) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    
    try {
      const cognitoResp = await api.post("/api/auth/login", {
        email: userAuth.email,
        password: userAuth.password,
      });

      if (cognitoResp && cognitoResp.status === 202) {
        const accessToken = cognitoResp.data.AuthenticationResult.AccessToken;
        const refreshToken = cognitoResp.data.AuthenticationResult.RefreshToken;
        const idToken = cognitoResp.data.AuthenticationResult.IdToken;

        // Store tokens in local storage
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("idToken", idToken);

        // Reset form
        setUserAuth({
          email: "",
          password: "",
        });

        navigate("/dashboard");
      } else if (cognitoResp && cognitoResp.status === 401) {
        if (cognitoResp.data.code === "NotAuthorizedException") {
          setError(cognitoResp.data.message);
        } else {
          setError("Failed to authenticate user");
        }
      } else {
        console.error("Unexpected response:", cognitoResp);
        setError("An unexpected error occurred");
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      setError(error.message || "Failed to connect to the server. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const styles = {
    pageContainer: {
      minHeight: '100vh',
      width: '100%',
      display: 'flex',
      position: 'relative',
      overflow: 'hidden',
    },
    leftSection: {
      flex: 1.6,
      position: 'relative',
      display: { xs: 'none', md: 'block' },
      background: 'linear-gradient(135deg, #2c1338 0%, #1a1a1a 100%)',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `
          radial-gradient(circle at 20% 30%, rgba(186, 104, 200, 0.2) 0%, transparent 30%),
          radial-gradient(circle at 80% 70%, rgba(186, 104, 200, 0.2) 0%, transparent 30%),
          radial-gradient(circle at 50% 50%, rgba(186, 104, 200, 0.1) 0%, transparent 50%)
        `,
        opacity: 0.8,
      },
    },
    contentBox: {
      position: 'relative',
      zIndex: 1,
      p: 6,
      color: '#fff',
      mt: 6,
      ml: 4,
      maxWidth: '800px',
    },
    rightSection: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      bgcolor: '#fff',
      p: 4,
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        width: '300px',
        height: '300px',
        background: 'radial-gradient(circle, rgba(156, 39, 176, 0.05) 0%, transparent 70%)',
        zIndex: 0,
      },
    },
    title: {
      fontSize: { md: '2.5rem', lg: '3rem' },
      fontWeight: 700,
      lineHeight: 1.2,
      mb: 2,
      color: '#fff',
    },
    subtitle: {
      fontSize: { md: '1.1rem', lg: '1.25rem' },
      maxWidth: '500px',
      color: 'rgba(255, 255, 255, 0.8)',
      mb: 6,
      lineHeight: 1.6,
    },
    featuresContainer: {
      mt: 6,
    },
    purpleAccent: {
      position: 'absolute',
      width: '200px',
      height: '200px',
      borderRadius: '50%',
      background: 'radial-gradient(circle, rgba(186, 104, 200, 0.15) 0%, transparent 70%)',
      filter: 'blur(40px)',
      animation: 'float 10s ease-in-out infinite',
    },
    textField: {
      '& .MuiInputBase-root': {
        color: '#000',
        '&:before': {
          borderBottomColor: alpha('#ba68c8', 0.42),
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottomColor: '#ba68c8',
        },
        '&.Mui-focused:after': {
          borderBottomColor: '#ba68c8',
        },
      },
      '& .MuiInputLabel-root': {
        color: alpha('#000', 0.6),
        '&.Mui-focused': {
          color: '#ba68c8',
        },
      },
    },
    submitButton: {
      height: 48,
      borderRadius: '8px',
      background: 'linear-gradient(45deg, #9c27b0, #ba68c8)',
      '&:hover': {
        background: 'linear-gradient(45deg, #ba68c8, #9c27b0)',
        transform: 'translateY(-2px)',
        boxShadow: '0 6px 20px rgba(186, 104, 200, 0.25)',
      },
      transition: 'all 0.3s ease-in-out',
    },
    linkedInButton: {
      width: '100%',
      backgroundColor: '#0077B5',
      color: 'white',
      textTransform: 'none',
      fontSize: '16px',
      fontFamily: 'system-ui, -apple-system, sans-serif',
      padding: '10px',
      marginBottom: '16px',
      '&:hover': {
        backgroundColor: '#006097',
      },
    },
    googleButton: {
      height: 48,
      borderRadius: '4px',
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid rgb(218, 220, 224)',
      fontFamily: 'Google Sans, Roboto, sans-serif',
      fontWeight: 500,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#f8f9fa',
        borderColor: '#dadce0',
        boxShadow: '0 1px 2px rgba(60,64,67,0.1)',
      },
    },
    checkbox: {
      color: '#ba68c8',
      '&.Mui-checked': {
        color: '#ba68c8',
      },
    },
    link: {
      color: '#ba68c8',
      textDecoration: 'none',
      '&:hover': {
        color: '#9c27b0',
      },
    },
  };

  return (
    <Box sx={styles.pageContainer}>
      <Box sx={styles.leftSection}>
        <Box sx={styles.contentBox}>
          <QuoteSection />
          <InteractiveScene />
        </Box>
      </Box>

      <Box sx={styles.rightSection}>
        <Container maxWidth="sm">
          <Box sx={{ width: '100%', maxWidth: 400, mx: 'auto' }}>
            <Typography variant={isMobile ? "h4" : "h3"} sx={{ mb: 4, fontWeight: 700, color: '#2c1338', textAlign: 'center' }}>
              Welcome Back
            </Typography>

            <form onSubmit={(e) => handleLoginAccount(e, userAuth)}>
              <Stack spacing={3}>
                {error && (
                  <Alert severity="error" sx={{ bgcolor: alpha(theme.palette.error.main, 0.1), borderRadius: 1 }}>
                    {error}
                  </Alert>
                )}

                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  required
                  value={userAuth.email}
                  onChange={handleChange}
                  sx={styles.textField}
                />

                <FormControl fullWidth variant="standard" sx={styles.textField}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required
                    value={userAuth.password}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(prev => !prev)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                          sx={{ color: '#ba68c8' }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <FormControl>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Checkbox id="remember" sx={styles.checkbox} />
                      <Typography variant="body2" component="label" htmlFor="remember" sx={{ color: alpha('#2c1338', 0.8) }}>
                        Remember me
                      </Typography>
                    </Stack>
                  </FormControl>
                  <Link 
                    to="/forgot-password" 
                    style={{ 
                      color: '#ba68c8',
                      textDecoration: 'none',
                      '&:hover': {
                        color: '#9c27b0',
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    Forgot Password?
                  </Link>
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={isLoading}
                  sx={styles.submitButton}
                >
                  {isLoading ? (
                    <CircularProgress size={24} sx={{ color: '#fff' }} />
                  ) : (
                    'Sign in'
                  )}
                </Button>

                <Box sx={{ textAlign: 'center', position: 'relative', my: 2 }}>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      bgcolor: '#fff',
                      px: 2,
                      color: alpha('#2c1338', 0.6),
                      position: 'relative',
                      zIndex: 1,
                    }}
                  >
                    Or
                  </Typography>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      right: 0,
                      height: '1px',
                      bgcolor: alpha('#2c1338', 0.1),
                      zIndex: 0,
                    }}
                  />
                </Box>

                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<LinkedInIcon />}
                  sx={styles.linkedInButton}
                  onClick={() => {
                    setError("LinkedIn Sign-in is currently under development. Please use email login.");
                  }}
                >
                  Sign in with LinkedIn
                </Button>

                <Typography variant="body2" align="center" sx={{ color: alpha('#2c1338', 0.8) }}>
                  Don't have an account?{" "}
                  <Link 
                    to="/signup" 
                    style={{ 
                      color: '#ba68c8',
                      textDecoration: 'none',
                      '&:hover': {
                        color: '#9c27b0',
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    Sign up
                  </Link>
                </Typography>
              </Stack>
            </form>
          </Box>
        </Container>
      </Box>

      <style>
        {`
          @keyframes float {
            0% { transform: translate(0, 0) rotate(0deg); }
            50% { transform: translate(10px, -10px) rotate(3deg); }
            100% { transform: translate(0, 0) rotate(0deg); }
          }
        `}
      </style>
    </Box>
  );
}
