// src/components/ResultComponent.js
import React from 'react';
import Defender from './Archetypes/Defender';
import Explorer from './Archetypes/Explorer';
import Innovator from './Archetypes/Innovator';

const ResultComponent = ({ overallScore, firstName, handleRetakeAssessment, sectionScores, archetype }) => (
  <>
    {overallScore < -5 ? (
      <Defender name={firstName} handleRetakeAssessment={handleRetakeAssessment} sectionScores={sectionScores} archetype={archetype} />
    ) : overallScore > -5 && overallScore < 5 ? (
      <Explorer name={firstName} handleRetakeAssessment={handleRetakeAssessment} sectionScores={sectionScores} archetype={archetype} />
    ) : (
      <Innovator name={firstName} handleRetakeAssessment={handleRetakeAssessment} sectionScores={sectionScores} archetype={archetype} />
    )}
  </>
);

export default ResultComponent;
