import React, { useState, useEffect } from "react";
import api from "../api";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Badge,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import PaymentIcon from "@mui/icons-material/Payment";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import NotificationsIcon from "@mui/icons-material/Notifications";
import "../styles/styles.css";
import "../styles/tokens.css";

const customCheckboxStyle = {
  borderRadius: "50%",
  width: "20px",
  height: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: "#BDBDBD",
};

const checkedStyle = {
  backgroundColor: "var(--accent-color)",
};

const drawerWidth = 300;

const capitalizeEachWord = (str) => {
  const words = str.split(" ");
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(" ");
};

export default function DashNavBar({ userAuth, page }) {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [checkedItem, setCheckedItem] = useState(page);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSidebar, setAnchorElSidebar] = useState(null);
  const [verticalNames, setVerticalNames] = useState([]);
  
  useEffect(() => {
    const fetchVerticalNames = async () => {
      try {
        const names = [];
        const response = await api.get(`/api/verticals/user/${userAuth._id}`);
        for (const vertical of response.data.verticals) {
          names.push(capitalizeEachWord(vertical.vertical));
        }
        setVerticalNames(() => ["Dashboard", ...names]);
      } catch (error) {
        console.error("Error fetching vertical names:", error);
      }
    };

    fetchVerticalNames();
  }, [userAuth]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    console.log("Toggle");
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleSideBarClick = (page) => {
    setCheckedItem(page);
    navigate(`/${page.toLowerCase().replace(" ", "-")}`);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAdd = (event) => {
    setAnchorElSidebar(event.currentTarget);
  };

  const handleCloseAdd = () => {
    setAnchorElSidebar(null);
  };

  const handleMenuItemClick = (item) => {
    handleClose();
    if (item === "Logout") {
      logoutUser();
    } else {
      // TODO: Handle other menu items as needed
    }
  };

  const logoutUser = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    navigate("/");
  };

  const drawer = (
    <div
      style={{
        backgroundColor: "var(--nav-color)",
        height: "100%",
      }}
    >
      <Toolbar
        style={{
          borderBottom: "1px solid #ccc",
        }}
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <img
            src={process.env.PUBLIC_URL + "/images/prism.jpg"}
            alt="Logo"
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              marginRight: "15px",
              display: "flex",
              alignItems: "center",
            }}
          />
        </Link>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Typography
            className="text"
            style={{ color: "var(--body-text-color)", fontWeight: 550 }}
          >
            Synergistic Intelligence
          </Typography>
        </Link>
      </Toolbar>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "10%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10%",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <Typography
            className="text"
            style={{
              fontWeight: 550,
            }}
          >
            MENU
          </Typography>
          <Tooltip title="Add new vertical" arrow>
            <IconButton
              onClick={handleClickAdd}
              aria-controls="simple-menu"
              aria-haspopup="true"
              disableRipple
            >
              <AddIcon style={{ color: "#ccc", marginRight: 0 }} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorElSidebar}
            keepMounted
            open={Boolean(anchorElSidebar)}
            onClose={handleCloseAdd}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: -5,
              horizontal: "center",
            }}
          >
            {["Real Estate", "Education", "Politics"].map((item, index) => (
              <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
                <Typography className="text" color="black">
                  {item}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </div>

        <List>
          {verticalNames.map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => handleSideBarClick(text)}>
                <div
                  className="text"
                  style={{
                    ...customCheckboxStyle,
                    ...(checkedItem === text && checkedStyle),
                    marginRight: "36px",
                  }}
                />
                <ListItemText
                  primary={text}
                  className="text"
                  primaryTypographyProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Typography
          className="text"
          style={{
            fontWeight: 550,
            marginTop: "10%",
            marginLeft: "5%",
          }}
        >
          OTHERS
        </Typography>
        <List>
          {[
            { text: "Settings", icon: <SettingsIcon />, path: "/settings" },
            { text: "Payment", icon: <PaymentIcon />, path: "/payment" },
            { text: "Account", icon: <PersonIcon />, path: "/account" },
            { text: "Help", icon: <InfoIcon />, path: "/help" },
          ].map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton component={Link} to={item.path}>
                <ListItemIcon
                  sx={{
                    color: "var(--body-text-color)",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  className="text"
                  primaryTypographyProps={{
                    color:
                      item.text === page
                        ? "var(--accent-color)"
                        : "var(--body-text-color)",
                    fontWeight: item.text === page ? "bold" : "normal",
                    fontFamily: "Montserrat",
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );

  return (
    <Box
      sx={{ display: "flex", minHeight: "100vh", bgcolor: "var(--nav-color)" }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: "var(--nav-color)",
          borderBottom: "1px solid #ccc",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            // color="var(--body-text-color)" TODO: fix
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <div style={{ flexGrow: 1 }} />

          <IconButton>
            <AccountCircleIcon style={{ color: "#ccc", fontSize: "2rem" }} />
          </IconButton>

          <Typography
            className="text"
            style={{
              color: "var(--body-text-color)",
              fontSize: 14,
              fontWeight: 550,
            }}
          >
            {userAuth.name}
          </Typography>

          <IconButton
            onClick={handleClick}
            aria-controls="simple-menu"
            aria-haspopup="true"
            disableRipple
          >
            <KeyboardArrowDownIcon style={{ color: "#ccc", marginRight: 0 }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: -20,
              horizontal: "right",
            }}
          >
            {["Profile", "Settings", "Logout"].map((item, index) => (
              <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
                <Typography className="text" color="black">
                  {item}
                </Typography>
              </MenuItem>
            ))}
          </Menu>

          <IconButton color="inherit">
            <Badge badgeContent={3} color="error">
              <NotificationsIcon
                style={{ color: "#ccc", fontSize: "1.5rem" }}
              />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="side-navigation"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          open
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
