import React from 'react';
import {
  Typography,
  Box,
  IconButton,
  Snackbar,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useLocation } from 'react-router-dom';

// Custom X (Twitter) Icon component
const XIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.1761 4H19.9362L13.9061 10.7774L21 20H15.4456L11.0951 14.4066L6.11723 20H3.35544L9.80517 12.7508L3 4H8.69545L12.6279 9.11262L17.1761 4ZM16.2073 18.3754H17.7368L7.86441 5.53928H6.2232L16.2073 18.3754Z" fill="currentColor"/>
  </svg>
);

export default function SocialMediaShare({ title = 'Check out this blog post!' }) {
    const location = useLocation();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    // Get the full URL of the current page
    const shareUrl = window.location.origin + location.pathname;
    const shareMessage = `Check out this amazing blog post: "${title}" on Synergistic Intelligence`;
    
    const handleShare = async (platform) => {
      let shareLink = '';
      
      switch (platform) {
        case 'linkedin':
          // LinkedIn sharing URL
          shareLink = `https://www.linkedin.com/sharing/share-offsite/?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(shareMessage)}`;
          break;
        case 'facebook':
          // Facebook sharing URL
          shareLink = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareMessage)}`;
          break;
        case 'x':
          // X (Twitter) sharing URL
          shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareMessage)}&hashtags=SynergisticIntelligence`;
          break;
        case 'copy':
          try {
            const fullMessage = `${shareMessage}\n\n${shareUrl}`;
            await navigator.clipboard.writeText(fullMessage);
            setSnackbarOpen(true);
            return;
          } catch (err) {
            console.error('Failed to copy:', err);
          }
          break;
        default:
          return;
      }
      
      if (shareLink) {
        // Open in a popup window
        const width = 600;
        const height = 400;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
        window.open(
          shareLink,
          'Share',
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
        );
      }
    };

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' }}>
          Share This Post
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton
            onClick={() => handleShare('linkedin')}
            sx={{
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: '8px',
              color: '#000000',
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                backgroundColor: '#e0e0e0',
                transform: 'scale(1.1)',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            onClick={() => handleShare('facebook')}
            sx={{
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: '8px',
              color: '#000000',
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                backgroundColor: '#e0e0e0',
                transform: 'scale(1.1)',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            onClick={() => handleShare('x')}
            sx={{
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: '8px',
              color: '#000000',
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                backgroundColor: '#e0e0e0',
                transform: 'scale(1.1)',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <XIcon />
          </IconButton>
          <IconButton
            onClick={() => handleShare('copy')}
            sx={{
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: '8px',
              color: '#000000',
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                backgroundColor: '#e0e0e0',
                transform: 'scale(1.1)',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <ShareIcon />
          </IconButton>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message="Link and message copied to clipboard!"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </Box>
    );
}