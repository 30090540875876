import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UserProvider } from "./components/UserContext";
import HomePage from "./pages/HomePage";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import About from "./pages/About";
import BlindSpot from "./pages/BlindSpot";
import EditablePage from "./pages/EditablePage";
import AccountVerification from "./pages/AccountVerification";
import BlogPage from "./pages/BlogPage";
import BlogPost from "./pages/BlogPost";
import FlowJourney from "./pages/FlowJourney";
import CreateFlowJourney from "./components/SI_Resources/Create_SI";
import FlowJourneyPage from "./pages/SI_Page";
import CreateBlog from "./components/Blog/CreateBlog";
import EditBlog from "./components/Blog/EditBlog";
import Assessment from "./components/Assessment/Assessment";
import AdditionalAssessment from "./components/Resources/AdditionalAssessment";
import QA from "./components/Assessment/QA";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import RealEstate from "./pages/RealEstate";
import Education from "./pages/Education";
import Politics from "./pages/Politics";
import Settings from "./pages/Settings";
import Payment from "./pages/Payment";
import Account from "./pages/Account";
import Help from "./pages/Help";
import Layout from "./components/Layout";

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/about" element={<About />} />
            <Route path="/blind-spot" element={<BlindSpot />} />
            <Route path="/editable-page/:tab/:title" element={<EditablePage />} />
            <Route path="/account-verification/:email" element={<AccountVerification />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:id" element={<BlogPost />} />
            <Route path="/flow-journey" element={<FlowJourney />} />
            <Route path="/create-flow-journey" element={<CreateFlowJourney />} />
            <Route path="/flow-journey-page/:id" element={<FlowJourneyPage />} />
            <Route path="/create-blog" element={<ProtectedRoute><CreateBlog /></ProtectedRoute>} />
            <Route path="/edit-blog/:id" element={<ProtectedRoute><EditBlog /></ProtectedRoute>} />
            <Route path="/assessment" element={<Assessment />} />
            <Route path="/additional-assessment" element={<AdditionalAssessment />} />
            <Route path="/QA" element={<QA />} />
            
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/real-estate" element={<RealEstate />} />
              <Route path="/education" element={<Education />} />
              <Route path="/politics" element={<Politics />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/account" element={<Account />} />
              <Route path="/help" element={<Help />} />
            </Route>
          </Route>
        </Routes>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
