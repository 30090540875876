import React, { useEffect } from 'react';
import InsightComponent from '../components/InsightComponent';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomePage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-out'
    });
  }, []);

  return (
    <div style={styles.app}>
      <Header />
      <Founders />
      <Hero />
      <Cards />
      <Features />
    </div>
  );
};

const Header = () => (
  <header style={styles.header}>
    <video
      src={process.env.PUBLIC_URL + '/Animation.mp4'}
      style={styles.video}
      autoPlay
      loop
      muted
      playsInline
    />
  </header>
);

const Hero = () => {
  const navigate = useNavigate();

  const handleAssessmentClick = () => {
    navigate('/assessment');
  };

  const handleLearnMoreClick = () => {
    navigate('/about');
  };

  return (
    <section style={styles.hero}>
      <div style={styles.heroContent}>
        <div style={styles.leftContent} data-aos="fade-right">
          <h1 style={styles.h1}>Unlock Your Potential Today</h1>
        </div>
        <div style={styles.rightContent} data-aos="fade-left" data-aos-delay="200">
          <p style={styles.p}>
            Discover how our tailored assessment can elevate yourself. 
            Take the first step towards transformation and unlock valuable insights.
          </p>
          <div style={styles.buttonContainer}>
            <button 
              style={styles.assessButton} 
              onClick={handleAssessmentClick}
              onMouseEnter={(e) => e.target.style.transform = 'translateY(-5px)'}
              onMouseLeave={(e) => e.target.style.transform = 'translateY(0)'}
            >
              Assessment
            </button>
            <button 
              style={styles.learnButton} 
              onClick={handleLearnMoreClick}
              onMouseEnter={(e) => e.target.style.transform = 'translateY(-5px)'}
              onMouseLeave={(e) => e.target.style.transform = 'translateY(0)'}
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

const Cards = () => (
  <section style={styles.cardsSection}>
    <h2 style={styles.cardsTitle} data-aos="fade-up">
      Drive Team Growth with Insights and <br/> Actionable Strategies
    </h2>
    <div style={styles.cardContainer}>
      <div data-aos="fade-up" data-aos-delay="0">
        <InsightComponent
          title="Transform Insights into Actionable Strategies for Team Success"
          text="Our assessment provides a clear roadmap to enhance team performance and collaboration."
          linkText="Start"
          link="/assessment"
        />
      </div>
      <div data-aos="fade-up" data-aos-delay="200">
        <InsightComponent
          icon="🔲"
          title="Step-by-Step: How to Leverage Your Assessment Results Effectively"
          text="Begin by taking the assessment, then analyze the results to identify strengths and areas for improvement."
          linkText="Analyze"
          link="/dashboard"
        />
      </div>
      <div data-aos="fade-up" data-aos-delay="400">
        <InsightComponent
          icon="🔲"
          title="Real Success Stories: Hear from Teams Who Transformed Their Dynamics"
          text="Discover how our assessment has empowered teams to achieve remarkable results."
          linkText="Inspire"
          link="/blog"
        />
      </div>
    </div>
  </section>
);

const Features = () => (
  <section style={styles.features}>
    <div style={styles.featureText} data-aos="fade-right">
      <h2 style={styles.featureTitle}>Unlock Hidden Strengths and Foster Team Success</h2>
      <p style={styles.featureDescription}>
        Our assessment identifies strengths and areas for growth within your team.
        By leveraging these insights, you can foster collaboration and drive performance.
      </p>
      <div style={styles.highlights}>
        <div style={styles.highlight} data-aos="zoom-in" data-aos-delay="200">
          <div style={styles.boostTitle}>Boost</div>
          <p>Teamwork and productivity through targeted strategies.</p>
        </div>
        <div style={styles.highlight} data-aos="zoom-in" data-aos-delay="400">
          <div style={styles.elevateTitle}>Elevate</div>
          <p>Your team's performance with actionable insights.</p>
        </div>
      </div>
    </div>
    <img 
      src={process.env.PUBLIC_URL + '/images/brilliant.png'} 
      alt="Inspiration" 
      style={styles.featureImage} 
      data-aos="fade-left"
      data-aos-delay="200"
    />
  </section>
);

const Founders = () => {
  return (
    <section style={styles.founders}>
      <div style={styles.foundersGlow} />
      <div style={styles.foundersContent}>
        <h2 style={styles.foundersTitle}>
          Meet Our <span style={styles.gradientText}>Visionaries</span>
        </h2>
        <div style={styles.foundersContainer}>
          <div style={styles.founderCard} data-aos="fade-right">
            <div style={styles.foundersVideoWrapper}>
              <div style={styles.foundersVideoGlow} />
              <div style={styles.foundersVideoOverlay} />
              <iframe
                src="https://www.youtube.com/embed/j6FNN_fcW4Q?start=1&rel=0&modestbranding=1"
                style={styles.foundersVideo}
                title="Stan Cross - Co-Founder"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div style={styles.founderInfo}>
              <h3 style={styles.founderName}>Stan Cross</h3>
              <p style={styles.founderRole}>Co-Founder</p>
            </div>
          </div>
          <div style={styles.founderCard} data-aos="fade-left">
            <div style={styles.foundersVideoWrapper}>
              <div style={styles.foundersVideoGlow} />
              <div style={styles.foundersVideoOverlay} />
              <iframe
                src="https://www.youtube.com/embed/pky1L9eR0Qs?start=3&rel=0&modestbranding=1"
                style={styles.foundersVideo}
                title="Alec Lance - Co-Founder"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div style={styles.founderInfo}>
              <h3 style={styles.founderName}>Alec Lance</h3>
              <p style={styles.founderRole}>Co-Founder</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const styles = {
  app: {
    backgroundColor: '#000',
    color: 'white',
    fontFamily: 'Poppins, sans-serif',
    margin: 0,
    padding: 0,
    marginTop: '6rem',
  },
  header: {
    position: 'relative',
    width: '100%',
    height: '50vh',
    overflow: 'hidden',
  },
  video: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: 'auto',
    minHeight: '100%',
    transform: 'translate(-50%, -50%)',
    objectFit: 'cover',
  },
  hero: {
    backgroundColor: '#000',
    padding: '5rem 0', 
  },
  heroContent: {
    width: '80%',
    maxWidth: '75rem', 
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftContent: {
    flex: 1,
    marginRight: '3rem', 
  },
  rightContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  h1: {
    fontSize: '3rem', 
    fontWeight: 'bold',
    color: 'white',
    margin: 0,
    textAlign: 'left',
  },
  p: {
    fontSize: '1.125rem',
    color: '#b0b0b0',
    lineHeight: '1.6',
    marginBottom: '1.25rem', 
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  assessButton: {
    padding: '0.75rem 1.5rem', 
    backgroundColor: '#a259ff',
    border: 'none',
    borderRadius: '0.3125rem', 
    color: 'white',
    fontSize: '1rem', 
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: '#8f3ff9',
      boxShadow: '0 8px 25px rgba(162, 89, 255, 0.4)',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-50%',
      left: '-50%',
      width: '200%',
      height: '200%',
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      transform: 'rotate(45deg)',
      transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
      opacity: 0,
    },
    '&:hover:before': {
      opacity: 1,
      transform: 'rotate(45deg) translate(50%, 50%)',
    }
  },
  learnButton: {
    padding: '0.75rem 1.5rem', 
    backgroundColor: 'transparent',
    border: '0.125rem solid #a259ff',
    borderRadius: '0.3125rem',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      borderColor: '#8f3ff9',
      color: '#8f3ff9',
      boxShadow: '0 8px 25px rgba(162, 89, 255, 0.2)',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-50%',
      left: '-50%',
      width: '200%',
      height: '200%',
      backgroundColor: 'rgba(162, 89, 255, 0.1)',
      transform: 'rotate(45deg)',
      transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
      opacity: 0,
    },
    '&:hover:before': {
      opacity: 1,
      transform: 'rotate(45deg) translate(50%, 50%)',
    }
  },
  cardsSection: {
    backgroundColor: '#000',
    padding: '3.75rem 0',
    marginTop: '3rem'
  },
  cardsTitle: {
    fontSize: '2.5rem', 
    fontWeight: '600',
    color: 'white',
    marginTop: '1%',
    textAlign: 'left',
    width: '80%',
    maxWidth: '75rem',
    margin: '3% auto 4% auto',
  },
  cardContainer: {
    width: '80%',
    maxWidth: '75rem', 
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1.25rem', 
  },
  features: {
    width: '80%', 
    maxWidth: '75rem',
    marginTop: '6rem',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    padding: '3.125rem 0',
    backgroundColor: '#000',
    alignItems: 'center',
    gap: '2rem',
  },
  featureText: {
    flex: 1,
    textAlign: 'left',       
  },
  featureTitle: {
    fontSize: '2.5rem',    
    fontWeight: '600',
    color: 'white',
    marginBottom: '1rem',
  },
  featureDescription: {
    fontSize: '1.125rem',  
    color: '#b0b0b0',
    lineHeight: '1.6',
    marginBottom: '2rem',
  },
  highlights: {
    display: 'flex',
    gap: '1.5rem',
    marginTop: '1.25rem', 
  },
  highlight: {
    flex: 1,
    padding: '1.5rem',
    borderRadius: '0.5rem',
    backgroundColor: 'rgba(162, 89, 255, 0.1)',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    border: '1px solid transparent',
    '&:hover': {
      transform: 'translateY(-5px)',
      border: '1px solid #a259ff',
      boxShadow: '0 10px 30px rgba(162, 89, 255, 0.2)',
    }
  },
  boostTitle: {
    fontSize: '1.5rem',
    color: '#f1c40f',
    marginBottom: '0.5rem',
    fontWeight: '600',
  },
  elevateTitle: {
    fontSize: '1.5rem',
    color: '#9333EA',
    marginBottom: '0.5rem',
    fontWeight: '600',
  },
  featureImage: {
    flex: 1,
    maxWidth: '100%',         
    height: 'auto',
    borderRadius: '0.5rem',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: '0 15px 30px rgba(162, 89, 255, 0.3)',
    }
  },
  glowingOrb: {
    position: 'absolute',
    width: '400px',
    height: '400px',
    borderRadius: '50%',
    background: 'radial-gradient(circle, rgba(162, 89, 255, 0.2) 0%, rgba(162, 89, 255, 0) 70%)',
    top: '-100px',
    left: '-100px',
    filter: 'blur(40px)',
    animation: 'float 8s ease-in-out infinite',
    zIndex: 0,
  },
  glowingOrb2: {
    position: 'absolute',
    width: '500px',
    height: '500px',
    borderRadius: '50%',
    background: 'radial-gradient(circle at center, rgba(89, 162, 255, 0.15) 0%, rgba(89, 162, 255, 0) 70%)',
    bottom: '-150px',
    right: '-150px',
    filter: 'blur(50px)',
    animation: 'float 10s ease-in-out infinite reverse',
    zIndex: 0,
  },
  gradientText: {
    background: 'linear-gradient(135deg, #9333EA, #C084FC)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
  },
  titleUnderline: {
    width: '100px',
    height: '4px',
    background: 'linear-gradient(90deg, #a259ff, #59a2ff)',
    margin: '20px auto',
    borderRadius: '2px',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      background: 'inherit',
      filter: 'blur(4px)',
    },
  },
  founderTitle: {
    fontSize: '3rem',
    marginBottom: '60px',
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: '3px',
    position: 'relative',
    zIndex: 2,
  },
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '80px',
    flexWrap: 'wrap',
    maxWidth: '1400px',
    margin: '0 auto',
    padding: '0 20px',
    position: 'relative',
    zIndex: 2,
  },
  videoWrapper: {
    flex: '1',
    minWidth: '400px',
    maxWidth: '600px',
    background: 'rgba(255, 255, 255, 0.03)',
    borderRadius: '30px',
    overflow: 'hidden',
    transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
    cursor: 'pointer',
    position: 'relative',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0 15px 35px rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
    '&:hover': {
      transform: 'translateY(-15px) scale(1.02)',
      boxShadow: '0 30px 60px rgba(162, 89, 255, 0.3)',
      border: '1px solid rgba(162, 89, 255, 0.3)',
      '& > div[class*="videoGlow"]': {
        opacity: 1,
      },
      '& > div[class*="videoOverlay"]': {
        opacity: 0.2,
      },
    },
  },
  videoGlow: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    background: 'radial-gradient(circle at center, rgba(162, 89, 255, 0.2) 0%, rgba(0, 0, 0, 0) 70%)',
    opacity: 0,
    transition: 'all 0.5s ease',
    zIndex: 1,
    pointerEvents: 'none',
  },
  videoOverlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%)',
    opacity: 0.1,
    transition: 'all 0.5s ease',
    zIndex: 2,
    pointerEvents: 'none',
  },
  founderVideo: {
    width: '100%',
    display: 'block',
    borderRadius: '30px',
    position: 'relative',
    zIndex: 3,
  },
  founders: {
    position: 'relative',
    padding: '8rem 0',
    backgroundColor: '#000',
    overflow: 'hidden',
  },
  foundersGlow: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    background: 'radial-gradient(circle at center, rgba(147, 51, 234, 0.15) 0%, rgba(0, 0, 0, 0) 70%)',
    pointerEvents: 'none',
  },
  foundersContent: {
    position: 'relative',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 2rem',
    zIndex: 1,
  },
  foundersTitle: {
    fontSize: '3.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '4rem',
    color: 'white',
    letterSpacing: '1px',
  },
  foundersHighlight: {
    flex: 1,
    padding: '1.5rem',
    borderRadius: '0.5rem',
    backgroundColor: 'rgba(162, 89, 255, 0.1)',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    border: '1px solid transparent',
    '&:hover': {
      transform: 'translateY(-5px)',
      border: '1px solid #a259ff',
      boxShadow: '0 10px 30px rgba(162, 89, 255, 0.2)',
    }
  },
  founderInfo: {
    textAlign: 'center',
    padding: '1rem',
  },
  founderName: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: 'white',
    marginBottom: '0.5rem',
    background: 'linear-gradient(135deg, #9333EA, #C084FC)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
  },
  founderRole: {
    fontSize: '1.2rem',
    color: '#9333EA',
    opacity: 0.8,
    marginTop: 0,
    fontWeight: '300',
  },
  foundersContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '4rem',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  founderCard: {
    flex: 1,
    maxWidth: '500px',
    background: 'linear-gradient(145deg, rgba(147, 51, 234, 0.1), rgba(0, 0, 0, 0.3))',
    borderRadius: '20px',
    padding: '1.5rem',
    border: '1px solid rgba(147, 51, 234, 0.2)',
    transition: 'all 0.4s ease',
    '&:hover': {
      transform: 'translateY(-10px)',
      boxShadow: '0 20px 40px rgba(147, 51, 234, 0.2)',
      border: '1px solid rgba(147, 51, 234, 0.4)',
      '& div[class*="videoGlow"]': {
        opacity: 0.8,
      },
    },
  },
  foundersVideoWrapper: {
    position: 'relative',
    width: '100%',
    borderRadius: '15px',
    overflow: 'hidden',
    marginBottom: '1.5rem',
  },
  foundersVideoGlow: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'radial-gradient(circle at center, rgba(147, 51, 234, 0.3) 0%, rgba(0, 0, 0, 0) 70%)',
    opacity: 0,
    transition: 'opacity 0.4s ease',
    pointerEvents: 'none',
    zIndex: 1,
  },
  foundersVideoOverlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%)',
    pointerEvents: 'none',
    zIndex: 2,
  },
  foundersVideo: {
    width: '100%',
    aspectRatio: '16/9',
    borderRadius: '15px',
    position: 'relative',
    zIndex: 3,
    border: 'none',
  },
}


export default HomePage;
