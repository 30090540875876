import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const BlogCard = ({ post }) => {
    return (
        <Link to={`/blog/${post._id}`} style={{ textDecoration: 'none' }}>
            <Paper
                elevation={3}
                sx={{
                    padding: '1.5rem',
                    backgroundColor: '#1a1a1a',
                    color: '#FFFFFF',
                    height: '100%',
                    borderRadius: '0.625rem',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                        transform: 'translateY(-0.5rem)',
                        boxShadow: '0 0.5rem 1rem rgba(0,0,0,0.2)',
                    },
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '12rem',
                        overflow: 'hidden',
                        borderRadius: '0.625rem',
                        marginBottom: '1rem',
                    }}
                >
                    <img
                        src={post.image}
                        alt={post.title}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '0.625rem',
                        }}
                    />
                </Box>

                <Typography variant="subtitle2" sx={{ color: 'gray', marginBottom: '0.5rem' }}>
                    {post.category || 'Category'}
                </Typography>

                <Typography
                    variant="h6"
                    component="h3"
                    sx={{ fontWeight: 'bold', color: 'var(--accent-color)', marginBottom: '0.5rem' }}
                >
                    {post.title}
                </Typography>

                <Typography variant="body2" sx={{ color: '#cccccc', marginBottom: '1rem' }}>
                    {post.excerpt}
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <Box>
                        <Typography variant="caption" sx={{ color: '#FFFFFF' }}>
                            {post.author || 'Author Name'}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'gray', display: 'block' }}>
                            {new Date(post.date).toLocaleDateString()} • {post.estimatedTime} min read
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </Link>
    );
};

export default BlogCard;
