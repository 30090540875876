import React, { useState, useRef} from 'react';
import { Container, Typography, Box, TextField, Button, Snackbar, Alert, Divider, Select, MenuItem, InputLabel, FormControl, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import Editor from '../Editor';
import api from '../../api';
import SocialMediaShare from '../SocialMediaShare';

const CreateBlog = () => {
  const navigate = useNavigate();
  const { userData } = useUser();
  const editorRef = useRef(null); // Ref for Editor
  const [hovered, setHovered] = useState(false);
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState(null);
  const [readingTime, setReadingTime] = useState(0); // New state for reading time
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isPublishing, setIsPublishing] = useState(false);

  const categories = ["Abundance", "Education", "Innovation", "Letting Go", "Tools for Awaking", "Thriving with AI"];

  // Function to calculate reading time based on text content
  const calculateReadingTime = (text) => {
    const wordsPerMinute = 200; // Average reading speed
    const words = text.trim().split(/\s+/).length;
    const time = Math.ceil(words / wordsPerMinute);
    
    return Math.max(time, 5);
};

  const handleCreateBlog = async () => {
    if (!title || !category) {
      setSnackbarMessage('Please fill all fields');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    
    setIsPublishing(true);
    const markdownContent = editorRef.current.getMarkdownContent();
    // Prepare form data for submission
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', JSON.stringify(markdownContent));
    formData.append('category', category);
    formData.append('author', userData.name);
    formData.append('estimatedTime', readingTime);
    if (image) {
      formData.append('image', image);
    }

    try {
      await api.post('/api/blogs', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setSnackbarMessage('Blog created successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate('/blog');
      }, 1000); // Give time for the success message to be seen
    } catch (error) {
      setSnackbarMessage('Error creating blog');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsPublishing(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  // Update reading time whenever content changes in the editor
  const handleEditorContentChange = () => {
    const markdownContent = editorRef.current.getMarkdownContent();
    setReadingTime(calculateReadingTime(markdownContent)); // Update reading time based on content
  };

  return (
    <Container sx={{ paddingTop: '8rem', color: 'white', minHeight: '100vh', paddingBottom: '40px' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '1rem', color: '#AF69ED' }}>
        Create New Blog Post
      </Typography>
      <Divider sx={{ marginBottom: '2rem', borderColor: 'gray' }} />

      <Container
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 2,
          marginBottom: '6rem',
          flexDirection: { xs: 'column', md: 'row' },
          maxWidth: '100%',
          height: '400px',
        }}
      >
        <Box sx={{ flex: 1, textAlign: 'left' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <Typography variant="body2" sx={{ color: 'gray', marginRight: 2 }}>
              Blog {'>'} {category || 'Select Category'}
            </Typography>
            <FormControl variant="standard" sx={{ background: "#FFF", minWidth: 200 }}>
              <InputLabel>Select Category</InputLabel>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                label="Select Category"
              >
                {categories.map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <TextField
            placeholder="Enter blog title here"
            variant="standard"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            multiline
            maxRows={5}
            InputProps={{
              sx: { fontSize: '2.5rem', fontWeight: 'bold', color: '#AF69ED', lineHeight: 1 },
            }}
            sx={{ marginBottom: '2rem', width: '90%' }}
          />

          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>By {userData?.name || "Your Name"}</Typography>
          <Typography variant="body2" sx={{ color: 'gray', marginBottom: '2rem' }}>{new Date().toLocaleDateString()} • {readingTime} min read</Typography>
          <SocialMediaShare />
        </Box>

        <Box
          sx={{
            flex: 1,
            maxWidth: '50%',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
            position: 'relative',
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <label htmlFor="imageUpload" style={{ width: '100%', cursor: 'pointer', position: 'relative' }}>
            <img
              src={image ? URL.createObjectURL(image) : process.env.PUBLIC_URL + '/images/placeholder.png'}
              alt="Blog post preview"
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '425px',
                objectFit: 'cover',
                borderRadius: '10px',
                transition: 'transform 0.3s ease',
                transform: hovered ? 'scale(1.05)' : 'scale(1)',
                filter: hovered ? 'brightness(70%)' : 'none',
              }}
            />
            {hovered && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  borderRadius: '10px',
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                }}
              >
                Click to upload
              </Box>
            )}
          </label>
          <input
            type="file"
            id="imageUpload"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
        </Box>
      </Container>

      <Box sx={{ width: '85%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'left', marginTop: '2rem', backgroundColor: '#1e1e1e', padding: '1rem', borderRadius: '8px' }}>
        <Editor ref={editorRef} onChange={handleEditorContentChange} />
      </Box>

      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleCreateBlog} 
        disabled={isPublishing}
        sx={{ 
          padding: '10px 20px', 
          fontSize: '1rem', 
          fontWeight: 'bold', 
          marginTop: '2rem',
          position: 'relative'
        }}
      >
        {isPublishing ? (
          <>
            Publishing...
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          </>
        ) : 'Publish Blog'}
      </Button>

      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</Alert>
      </Snackbar>
    </Container>
  );
};

export default CreateBlog;
