// src/components/FormNavigation.js
import React from 'react';
import { Box, Button, Stepper, Step, StepLabel } from '@mui/material';

const FormNavigation = ({ currentSection, totalSections, onBack, onNext, onSubmit, isLoading }) => {
  const isLastSection = currentSection === totalSections - 1;

  const handleNext = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onNext();
  };

  const handleBack = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onBack();
  };

  const handleSubmit = (e) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onSubmit(e);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
      <Button
        variant="contained"
        color="secondary"
        sx={{ 
          padding: '10px 20px', 
          width: { xs: '100%', md: 'auto' },
          backgroundColor: 'rgba(179, 136, 255, 0.8)',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: 'rgba(179, 136, 255, 1)',
          },
          '&.Mui-disabled': {
            backgroundColor: 'rgba(179, 136, 255, 0.3)',
            color: 'rgba(255, 255, 255, 0.5)',
          }
        }}
        onClick={handleBack}
        disabled={currentSection === 0 || isLoading}
        type="button"
      >
        Back
      </Button>
      <Stepper 
        activeStep={currentSection} 
        alternativeLabel 
        sx={{ 
          flexGrow: 1, 
          mx: { xs: 0, md: 2 }, 
          width: '100%',
          '& .MuiStepLabel-label': {
            color: '#E0E0E0',
            '&.Mui-active': {
              color: '#B388FF',
            },
            '&.Mui-completed': {
              color: '#80CBC4',
            }
          },
          '& .MuiStepIcon-root': {
            color: 'rgba(179, 136, 255, 0.3)',
            '&.Mui-active': {
              color: '#B388FF',
            },
            '&.Mui-completed': {
              color: '#80CBC4',
            }
          }
        }}
      >
        {[...Array(totalSections)].map((_, index) => (
          <Step key={index}>
            <StepLabel></StepLabel>
          </Step>
        ))}
      </Stepper>
      <Button
        variant="contained"
        color="primary"
        sx={{ 
          padding: '10px 20px', 
          width: { xs: '100%', md: 'auto' },
          backgroundColor: '#B388FF',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#9575CD',
          },
          '&.Mui-disabled': {
            backgroundColor: 'rgba(179, 136, 255, 0.3)',
            color: 'rgba(255, 255, 255, 0.5)',
          }
        }}
        onClick={isLastSection ? handleSubmit : handleNext}
        type="button"
        disabled={isLoading}
      >
        {isLoading ? 'Loading...' : isLastSection ? 'Submit' : 'Next'}
      </Button>
    </Box>
  );
};

export default FormNavigation;
